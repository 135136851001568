<html>

<head>
</head>

<body>
	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showGcNotRecRpt">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">GC Not Received Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">

											<div class="col-sm-12 col-md-12">

												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Godown</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-search"></i>
																</span>
															</div>
															<!--<input class="auto_selectOption input is-medium"
																placeholder="Select Godown" [formControl]="control"
																[appAutocomplete]="autocomplete">-->
															<input #destinationStation id="{{pageId}}destinationStationId"
																type="text" class="form-control"
																(selectItem)="clickListnerForDestinationStation($event)"
																[(ngModel)]="modelDestinationStation"
																[ngbTypeahead]="searchDestinationStation"
																[resultFormatter]="formatterDestinationStation"
																[inputFormatter]="formatterDestinationStation"
																(focus)="focusDestinationStationTA$.next($any($event).target.value)" />
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Till Date</label> <input class="form-control"
															placeholder="yyyy-mm-dd" id="{{pageId}}tillDates" name="tillDates"
															ngbDatepicker #tillDates="ngbDatepicker"
															[(ngModel)]="tillDatesModal" autocomplete="off">
														<div class="input-group-append" (click)="tillDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" (click)="validateGCNotRecSearchBtn()"
								id="{{pageId}}searchBtn">Search</button>
							<button type="submit" class="btn btn-dark" (click)="clearBtn()" id="{{pageId}}clearBtn">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<!-- spinner start-->
						<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;">
									</mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<div *ngIf="showSpinnerForActionForPopup" class="col-md-9 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;">
									</mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<!-- spinner end-->
						<div class="box-body">
						
						<button type="submit" class="dt-button" style="margin-left: 74%;"
								(click)="customPrintGCNotRec()" id="{{pageId}}printAllBtn">
								<span><i class="fas fa-print">Print</i></span>
							</button>
							
							<table datatable id="{{pageId}}gcNotReceivedReportTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsGcNotReceivedReport" [dtTrigger]="dtTriggerGcNotReceivedReport">

								<thead>
									<tr>
										<th>Date</th>
										<th>Memo No</th>
										<th>No.of.Articles</th>
										<th>LR No</th>
										<th>Consignee Name</th>
										<th>Memo Name</th>
										<th>Memo Amount</th>
										<th>Hamali DD</th>
										<th>Remarks</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let gcNotReceivedReportData of gcNotReceivedReportDataList ">
										<td>{{ gcNotReceivedReportData.memoDateStr }}</td>
										<td>{{ gcNotReceivedReportData.memoNumber }}</td>
										<td>{{ gcNotReceivedReportData.totalArticles }}</td>
										<td>{{ gcNotReceivedReportData.lrNumber }}</td>
										<td>{{ gcNotReceivedReportData.consignee }}</td>
										<td>{{ gcNotReceivedReportData.cashMemoName }}</td>
										<td>{{ gcNotReceivedReportData.amount }}</td>
										<td>{{ gcNotReceivedReportData.hamaliDD }}</td>
										<!-- <td>{{ gcNotReceivedReportData.remarks }}</td> -->
										<td style="cursor: pointer; color: blue;text-decoration: underline;" (click)="open(content,gcNotReceivedReportData)">{{ gcNotReceivedReportData.remarks }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>


						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
	<ng-template #content let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h6 class="modal-title" id="{{pageId}}modal-basic-title">Update GC not received remarks
            </h6>

            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">


                    <div class="col-sm-12 col-md-12">
                        <div class="control">
                            <div class="form-group">
                                <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                    <label>Remarks</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="ti-home"></i>
										</span>
                                        <input id="{{pageId}}gcNotRecRemarksId" type="text" class="form-control" [ngbTypeahead]="gcNotRecRemarksSearchTA" (focus)="focusGcNotRecRemarksTA$.next($any($event).target.value)" 	[resultFormatter]="formatterRemarks"
										[inputFormatter]="formatterRemarks"
										 placeholder="Select Remarks" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <button type="submit" class="btn btn-dark" id="{{pageId}}UpdatePopupBtn" (click)="updateGCNotRecRemarksBtn()">Update</button>
                        <button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</body>

</html>