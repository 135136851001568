import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef, NgModule } from '@angular/core';
//for datepicker starts
import { NgbDateStruct, NgbCalendar, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
//for datepicker ends
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { LRDto } from 'src/app/dto/LR-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { LrService } from "src/app/dataService/lr-service";

@Component({
    selector: 'app-lr-short-and-extra-consolidate-rptV2',
    templateUrl: './lr-short-and-extra-consolidate-rptV2.component.html',
    styleUrls: ['./lr-short-and-extra-consolidate-rptV2.component.css']
})
export class LrShortAndExtraConsolidateRptV2Component implements OnInit {

    searchByLrNo = true;
    searchByHireslipNo = false;
    searchByDateWise = false;
    viewDate = false;
    showSrc = false;
    showDest = false;
    isLoggedIn = true;
    userDataDtoReturnSession: any;

    showSpinnerForAction = false;
    rightsRoleForDestination = "Rights Role For Destination";

    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends
    // ngModel Id
    selectedSearchBy: any;
    enteredLrNumber: any;
    enteredHireslipNumber: any;
    selectedDateType: any;
    selectedFromDate: any;
    selectedToDate: any;
    selectedSource: any;
    selectedDestination: any;
    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends
    viewCustomPrintV1 = false
    lrDto: LRDto = new LRDto();

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerLrShortAndExtraConsolidate: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsLrShortAndExtraConsolidate: any;

    lrDtosSubStationOptions: any;
    lrDtoSubStation: LRDto = new LRDto();
    public modelSource: any;
    sourceSubStationNameTA: Array<LRDto> = [];
    focusSourceSubStationNameTA$ = new Subject<string>();
    sourceSubStationNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceSubStationNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceSubStationNameTA
                : this.sourceSubStationNameTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSourceSubStationName = (x: { subStations: string }) => x.subStations;

    destinationOptions: LRDto[];
    lrDtoParticularSubStation: LRDto = new LRDto();
    lrDtoAllOptionForSubStation: LRDto = new LRDto();
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    pageId = "lsecrcV2";
    //     dynamicHeaders = [

    //   { title: 'BKGTS/OPH/CB/2324/100', key: 'trip1', children: ['Load', 'Unload'] },
    //   { title: 'BKGTS/OPH/CB/2324/101', key: 'trip2', children: ['Load', 'Unload'] },
    //   { title: 'BKGTS/OPH/CB/2324/101', key: 'trip3', children: ['Load', 'Unload'] },
    //   { title: 'PUR/MDU/2324/45', key: 'trip4', children: ['Load', 'Unload'] },
    //   { title: 'MDU/TDM/2324/10', key: 'trip5', children: ['Load', 'Unload'] },
    //   { title: '', key: 'trip6', children: ['Load', 'Unload'] },
    //  ];

    // Data for the table
    //   lrShortAndExtraConsolidateDataList = [
    //     {

    //       trip1Load: 20,
    //       trip1Unload: 20,
    //       trip2Load: 20,
    //       trip2Unload: 18,
    //       trip3Load: 18,
    //       trip3Unload: 18,
    //       trip4Load: 18,
    //       trip4Unload: 18,
    //       trip5Load: 18,
    //       trip5Unload: 20,
    //       trip6Load: 20,
    //       trip6Unload: 20,
    //     },
    //     {

    //       trip1Load: 25,
    //       trip1Unload: 24,
    //       trip2Load: 24,
    //       trip2Unload: 24,
    //       trip3Load: 24,
    //       trip3Unload: 24,
    //       trip4Load: 22,
    //       trip4Unload: 22,
    //       trip5Load: 24,
    //       trip5Unload: 24,
    //       trip6Load: 24,
    //       trip6Unload: 24,

    //     },
    //   ];

    tableHeaders: any[];
    dynamicHeaders: { title: string; key: string }[] = [];
    lrShortAndExtraConsolidateDataList: any[] = [];

    constructor(private router: Router, private masterReadService: MasterReadService, public changeDetectorRef: ChangeDetectorRef, private lrService: LrService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            //          userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

            // for (let key in this.userDataDtoReturnSession.sortedMapFeatures) {
            //     //Whatever you want to do with key or obj[key]
            //     console.log(key);
            //     if (key == 'Rights') {
            //         for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
            //             //Whatever you want to do with key or obj[key]
            //             // console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights[i]);
            //             if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == 'Source ShortExtraLR') {
            //                 //console.log('Source ShortExtraLR');
            //                 // this.getUserGodownList();
            //             }
            //         }
            //     }
            // }

            //
            /*if (this.rightsRoleForDestination != null &&
                this.rightsRoleForDestination == "Rights Role For Destination" ||
                this.rightsRoleForDestination == "Rights Role For Destination" ||
                this.rightsRoleForDestination == "Rights Role For Destination" ||
                this.rightsRoleForDestination == "Rights Role For Destination") {
            */
            this.getUserGodownList();
            if (this.userDataDtoReturnSession.role != null &&
                this.userDataDtoReturnSession.role == "Super Administrator" ||
                this.userDataDtoReturnSession.role == "Transshipment Office" ||
                this.userDataDtoReturnSession.role == "Booking Manager" ||
                this.userDataDtoReturnSession.role == "Booking Administrator") {
                this.getDestinationDetails();
            } else {
                this.modelDestination = null;
                this.lrDtoParticularSubStation = new LRDto();
                this.lrDtoParticularSubStation.destination = this.userDataDtoReturnSession.mainStation;
                this.destinationTA.push(this.lrDtoParticularSubStation);
            }
            // this.getShortExtraLRWiseDetails();
        }

    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }




    ngOnInit(): void {
        var groupColumn = 0;
        this.dtOptionsLrShortAndExtraConsolidate = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                // footer: true,
                // exportOptions: {
                //     columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
                // },

                // title: function () {

                //     var returnSummaryExcelAmtColt = null;

                //     returnSummaryExcelAmtColt = "Advance Payment Report " +
                //         " From Date : " + moment($("#advpcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                //         "To Date : " + moment($("#advpctoDate").val()).format('DD-MM-YYYY');


                //     return returnSummaryExcelAmtColt;
                // }
            }],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);

            },
            // "drawCallback": function (settings) {
            //     var api = this.api();
            //     var rows = api.rows({
            //         page: 'current'
            //     }).nodes();
            //     var last = null;

            //     var api = this.api();
            //     var rows = api.rows({
            //         page: 'current'
            //     }).nodes();
            //     var last = null;
            //     var count = 0;
            //     //var pendingQty = 0;
            //     api.column(groupColumn, {
            //         page: 'current'
            //     }).data().each(function (group, i) {

            //         var val = api.row(api.row($(rows).eq(i)).index()).data();
            //         if (last !== group) {
            //             if (i != 0) {
            //                 $(rows)
            //                     .eq(i)
            //                     .before(
            //                         $(
            //                             "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
            //                             {
            //                                 "class": "group",
            //                                 "data-id": group
            //                             })
            //                             .append($("<td></td>",
            //                                 {
            //                                     "colspan": 1,
            //                                     "text": "Total"
            //                                 }))
            //                             .append($("<td></td>",
            //                                 {
            //                                     "colspan": 1,
            //                                     "text": + count
            //                                 }))
            //                             .append($("<td></td>",
            //                                 {
            //                                     "colspan": 13,
            //                                     "text": ""
            //                                 }))
            //                             .prop('outerHTML'));

            //                 count = 0;
            //                 //pendingQty = 0;
            //             }
            //             //the below is for column name grouping (in this case we group for destination wise)
            //             $(rows)
            //                 .eq(i)
            //                 .before(
            //                     $(
            //                         "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
            //                         {
            //                             "class": "group",
            //                             "data-id": group
            //                         })
            //                         .append(
            //                             $("<td></td>",
            //                                 {
            //                                     "colspan": 15,
            //                                     "text": (group+" ("+val[1]+", Art - "+val[3]+")")
            //                                 })).prop('outerHTML'));
            //             last = group;
            //         }
            //         count++;

            //         //pendingQty += val[6];
            //         if (i == (rows.length - 1)) {
            //             //the below is for Sumary details grouping 
            //             $(rows)
            //                 .eq(i)
            //                 .after(
            //                     $(
            //                         "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
            //                         {
            //                             "class": "group",
            //                             "data-id": group
            //                         })
            //                         .append($("<td></td>",
            //                             {
            //                                 "colspan": 1,
            //                                 "text": "Total"
            //                             }))
            //                         .append($("<td></td>",
            //                             {
            //                                 "colspan": 1,
            //                                 "text": + count
            //                             }))
            //                         .append($("<td></td>",
            //                             {
            //                                 "colspan": 13,
            //                                 "text": ""
            //                             })).prop('outerHTML'));

            //             count = 0;
            //             //pendingQty = 0;
            //         }
            //     });
            // }
        }



    }

    ngAfterViewInit(): void {
        this.dtTriggerLrShortAndExtraConsolidate.next();
    }
    ngOnDestroy(): void {
        this.dtTriggerLrShortAndExtraConsolidate.unsubscribe();
    }

    //input field hide and show starts
    searchByMode(searchBy: string) {
        if (searchBy === 'lrNoWise') {
            this.searchByLrNo = true;
            this.searchByHireslipNo = false;
            this.searchByDateWise = false;
            this.viewDate = false;
            this.showSrc = false;
            this.showDest = false;

        } else if (searchBy === 'hireslipNoWise') {
            this.searchByLrNo = false;
            this.searchByHireslipNo = true;
            this.searchByDateWise = false;
            this.viewDate = false;
            this.showSrc = false;
            this.showDest = false;


        } else if (searchBy === 'dateWise') {
            this.searchByLrNo = false;
            this.searchByHireslipNo = false;
            this.searchByDateWise = true;
            this.viewDate = true;
            this.showSrc = true;
            this.showDest = true;
        } else {
            this.searchByLrNo = false;
            this.searchByHireslipNo = false;
            this.searchByDateWise = false;
            this.viewDate = false;
            this.showSrc = false;
            this.showDest = false;

        }
    }
    sourceSubStationDropDownListner(event) {
        this.modelSource = event.item;
        // this.modelSource = event.item.subStations;
        $("#" + this.pageId + "modelSource").val(event.item.subStations);
    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        this.modelDestination = this.modelDestination.destination;
        $("#" + this.pageId + "modelDestination").val(this.modelDestination.destination);
    }

    getUserValuesForUserGodownList() {
        this.lrDtoSubStation = new LRDto();
        this.lrDtoSubStation.mode = "ALL";
        this.lrDtoSubStation.companyId = this.userDataDtoReturnSession.companyId;
    }

    getUserGodownList() {
        this.showSpinnerForAction = true;
        this.getUserValuesForUserGodownList();
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSubStation).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.lrDtosSubStationOptions = [];
                    this.sourceSubStationNameTA = [];
                    this.lrDtosSubStationOptions = response;
                    for (let i = 0; i < this.lrDtosSubStationOptions.length; i++) {
                        this.sourceSubStationNameTA.push(this.lrDtosSubStationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the SubStation Details", "error");
            },
            () => console.log('done');
    };

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.showSpinnerForAction = true;
        this.getDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationOptions = response;
                    this.destinationTA = [];
                    this.lrDtoAllOptionForSubStation = new LRDto();
                    this.lrDtoAllOptionForSubStation.destination = "ALL";
                    this.destinationTA.push(this.lrDtoAllOptionForSubStation);
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "error");
            },
            () => console.log('done');
    };

    validateForSearch() {
        this.selectedSearchBy = $("#" + this.pageId + "searchBy").val();
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        this.enteredHireslipNumber = $("#" + this.pageId + "hireslipNumber").val();
        this.selectedDateType = $("#" + this.pageId + "dateType").val();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedSource = $("#" + this.pageId + "source").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();


        console.log("searchBy >>" + this.selectedSearchBy);
        console.log("lrNumber >>" + this.enteredLrNumber);
        console.log("hireslipNumber >>" + this.enteredHireslipNumber);
        console.log("dateType >>" + this.selectedDateType);
        console.log("selectedFromDate >>" + this.selectedFromDate);
        console.log("selectedToDate >>" + this.selectedToDate);
        console.log("selectedSource >>" + this.selectedSource);
        console.log("selectedDestination >>" + this.selectedDestination);
        //  this.lrDto = new LRDto();


        if (this.selectedSearchBy == "lrNoWise") {
            if ((this.enteredLrNumber == null) || (this.enteredLrNumber == undefined) ||
                (this.enteredLrNumber == "")) {
                swal("Mandatory fields", "Lr Number is mandatory fields", "warning");
                return false;
            } else {
                this.getShortExtraLRWiseDetails();
            }
        } else if (this.selectedSearchBy == "hireslipNoWise") {
            if ((this.enteredHireslipNumber == null) || (this.enteredHireslipNumber == undefined) ||
                (this.enteredHireslipNumber == "")) {
                swal("Mandatory fields", "Hireslip Number is mandatory fields", "warning");
                return false;
            } else {
                this.getShortExtraHireslipWiseDetails();
            }
        } else if (this.selectedSearchBy == "dateWise") {
            if ((this.selectedFromDate == null) || (this.selectedFromDate == undefined) ||
                (this.selectedFromDate == "")
                || (this.selectedToDate == null) || (this.selectedToDate == undefined) ||
                (this.selectedToDate == "")
                || (this.selectedDateType == null) || (this.selectedDateType == undefined) ||
                (this.selectedDateType == "")
                || (this.selectedSource == null) || (this.selectedSource == undefined) ||
                (this.selectedSource == "")
                || (this.selectedDestination == null) || (this.selectedDestination == undefined) ||
                (this.selectedDestination == "")) {
                swal("Mandatory fields", "Please fill mandatory fields", "warning");
                return false;
            } else {
                this.getShortExtraHireslipDateWiseDetails();
            }
        }
    }
    searchDetailsBtn() {
        this.validateForSearch();
    }

    clearBtn() {
        this.searchByLrNo = true;
        this.searchByHireslipNo = false;
        this.searchByDateWise = false;
        this.viewDate = false;
        this.showSrc = false;
        this.showDest = false;
    }
    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }

    
    getShortExtraLRWiseDetailsMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.lrNumber = this.enteredLrNumber;
    }
    getShortExtraLRWiseDetails() {
        this.showSpinnerForAction = true;
        this.getShortExtraLRWiseDetailsMethod();

        this.lrService.getLRsShortExtraConsolidatedRptV2(this.lrDtoDestination).subscribe(
            (response) => {
                $("#" + this.pageId + "lrShortAndExtraConsolidateTableId").DataTable().destroy();
                this.lrShortAndExtraConsolidateDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Short & Extra",
                        text: "No details found!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.lrShortAndExtraConsolidateDataList = response;
                }
                setTimeout(() => {
                    this.dtTriggerLrShortAndExtraConsolidate.next();
                }, 1000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the details", "info");
            }, () => console.log('done');
    }






    destroyDataTable() {
        $("#" + this.pageId + "lrShortAndExtraConsolidateTableId").DataTable().destroy();
    }


    getShortExtraHireslipWiseDetailsMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.invoiceNumber = this.enteredHireslipNumber;
    }
    getShortExtraHireslipWiseDetails() {
        this.showSpinnerForAction = true;
        this.getShortExtraHireslipWiseDetailsMethod();

        this.lrService.getLRsShortExtraConsolidatedRptHireslipNo(this.lrDtoDestination).subscribe(
            (response) => {
                $("#" + this.pageId + "lrShortAndExtraConsolidateTableId").DataTable().destroy();
                this.lrShortAndExtraConsolidateDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Short & Extra",
                        text: "No details found!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    // this.lrShortAndExtraConsolidateDataList = response;
                }
                setTimeout(() => {
                    this.dtTriggerLrShortAndExtraConsolidate.next();
                }, 1000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the details", "info");
            }, () => console.log('done');
    }

    getShortExtraHireslipDateWiseDetailsMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.fromDate = this.selectedFromDate;
        this.lrDtoDestination.toDate = this.selectedToDate;
        this.lrDtoDestination.fromStation = this.selectedSource;
        this.lrDtoDestination.toStation = this.selectedDestination;
    }
    getShortExtraHireslipDateWiseDetails() {
        this.showSpinnerForAction = true;
        this.getShortExtraHireslipDateWiseDetailsMethod();

        this.lrService.getLRsShortExtraConsolidatedRptHireslipDate(this.lrDtoDestination).subscribe(
            (response) => {
                $("#" + this.pageId + "lrShortAndExtraConsolidateTableId").DataTable().destroy();
                this.lrShortAndExtraConsolidateDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Short & Extra",
                        text: "No details found!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    // this.lrShortAndExtraConsolidateDataList = response;
                }
                setTimeout(() => {
                    this.dtTriggerLrShortAndExtraConsolidate.next();
                }, 1000);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the details", "info");
            }, () => console.log('done');
    }
}
