//Chg_V1 : Get destination on enter pincode (Asrar Jr / 23-12-2024)

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from "angular-datatables";
import { Inject, ViewChild } from '@angular/core';
import { LRDto } from "src/app/dto/LR-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ReportService } from 'src/app/dataService/report-service';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from "@angular/common";
import swal from 'sweetalert';
import { ModalDismissReasons, NgbModal, NgbTypeahead, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseDto } from 'src/app/dto/Response-dto';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { FormControl } from '@angular/forms';
import { LrService } from 'src/app/dataService/lr-service';
import * as qz from 'qz-tray';

export interface DialogData {
    listOfDataToPass: string[];
    listOfDataToPasstoLrEntry: string[];
}
@Component({
    selector: 'app-generate-barcode',
    templateUrl: './generate-barcode.component.html',
    styleUrls: ['./generate-barcode.component.css']
})
export class GenerateBarcodeComponent implements OnInit {
    lrDto: LRDto = new LRDto();
    userDataDtoReturnSession: any;
    lrDetailsDataList: any;
    listOfDataToGet: any;
    list: string[];
    lrDtoMultiLrs: LRDto = new LRDto();
    lrDtoMultiLrsRet: any;
    selectedLrNumber: any;
    readonlyLrNo = true;
    pageId = "gbcs";
    loadingIndicator = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerLrDetails: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsLrDetails: any;
    enteredEWayBillNo: any;
    destinationOptions: LRDto[];
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestnationTA$ = new Subject<string>();

    destnationSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestnationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destinationState.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destinationState: string }) => x.destinationState;
    showSpinnerForAction = false;
    lrtDtoRespForEwayApi: LRDto = new LRDto();
    responseDtoForEwayApi: ResponseDto = new ResponseDto();
    enteredPincode: any;
    destinationMainStation: any;
    userDataDto: any;
    lrDtoSubstation: LRDto = new LRDto();
    controlDestination = new FormControl();
    enteredLrNumber: any;
    destination: any;
    PrintBarcode = [];
    articleCount: number = 1;
    isLoggedIn = true;
    dataForPrint: any;
    viewBarcodePrint = false;
    viewBarcodeEntryForm = true;
    lrDtoBarcode: LRDto = new LRDto();
    lrDtoForLrRet = new LRDto();
    closeResult: string;
    enteredManualLrNumber: any;
    enteredManualBarcodeArticles: any;
    constructor(
        private masterReadService: MasterReadService, private reportService: ReportService,
        private router: Router, private datePipe: DatePipe, public dialog: MatDialog,
        private route: ActivatedRoute, public changeDetectorRef: ChangeDetectorRef,
        private ewaybillService: EwaybillService, private lrService: LrService, private modalService: NgbModal
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

        }
        this.getHoldLrsDetails();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {

                dtInstance.destroy();
            });
        });
    }
    ngOnInit(): void {


        this.dtOptionsLrDetails = {
            dom: 'frtip',
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            searching: false,
            scrollX: true,
            scrollY: 280,
            scrollCollapse: true,
            paging: false,
            info: false,
            buttons: false,  // Disables all buttons
            footerCallback: function (row, data, start, end, display) {
                var api = this.api();

                // Remove the formatting to get integer data for summation
                var intVal = function (i) {
                    return typeof i === 'string' ? +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ? i : 0;
                };

                var columnData = api.column(0).data();

                // Update footer with the count of LR numbers
                $(api.column(0).footer()).html(
                    columnData.count()
                );
            }
        };

    }
    ngOnDestroy(): void {
        this.dtTriggerLrDetails.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerLrDetails.next();

    }



    getHoldLrsInfo() {
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.userName = this.userDataDtoReturnSession.userId;
    }
    getHoldLrsDetails = () => {
        this.getHoldLrsInfo();
        this.masterReadService.getblockedlrslistForBarcode(this.lrDto).subscribe(
            (response) => {
                if (response.length == 0) {

                    swal({
                        title: "Warning",
                        text: "No records found",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    $("#" + this.pageId + "totalLrs").val('');
                } else {
                    this.lrDetailsDataList = [];
                    $("#" + this.pageId + "barcodeTable").DataTable().destroy();
                    this.lrDetailsDataList = response;

                    $("#" + this.pageId + "lrNumber").val('');
                }
                this.readonlyLrNo = false;

                this.dtTriggerLrDetails.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => console.log(error.json()),
            () => console.log('done');
    };







    validateClear() {
        $("#" + this.pageId + "lrNumber").val('');
        $("#" + this.pageId + "eWayBillNo").val('');
        $("#" + this.pageId + "pinCode").val('');
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "articles").val('');
        this.destinationOptions = [];
        this.destinationTA = [];
    }



    getDetailsFromEwayBillRefreshEwb = () => {

        this.clearEwayBillDetails();
        this.showSpinnerForAction = true;
        this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
        this.lrtDtoRespForEwayApi = new LRDto();
        this.responseDtoForEwayApi = new ResponseDto();
        this.responseDtoForEwayApi.ewayBillNo = this.enteredEWayBillNo;
        //CHG-V-LR:26
        this.responseDtoForEwayApi.token1 = this.userDataDtoReturnSession.mainAdminStation;
        this.ewaybillService.getDetailsFromEwayBillRefreshEwb(this.responseDtoForEwayApi).subscribe(
            (response) => {

                this.showSpinnerForAction = false;
                this.lrtDtoRespForEwayApi = response;
                console.log(this.lrtDtoRespForEwayApi);

                if (this.lrtDtoRespForEwayApi.ewayBillStatus != null && this.lrtDtoRespForEwayApi.ewayBillStatus == "CANCEL") {
                    swal("Not Allowed", "This E-Way Bill Number : " + this.enteredEWayBillNo + "  show as Cancelled!.. Please Verify entered E-Way Bill No!", "warning");
                    $("#" + this.pageId + "eWayBillNo").val('');
                    this.enteredEWayBillNo = null;
                } else if (this.lrtDtoRespForEwayApi.isBlockedEwayBill) {
                    swal("Not Allowed", "The entered E-Way Bill No : " + this.enteredEWayBillNo + " is expiring today!.. Please do the extension to continue with the LR Generation.", "warning");
                    $("#" + this.pageId + "eWayBillNo").val('');
                    this.enteredEWayBillNo = null;
                } else {
                    if (this.lrtDtoRespForEwayApi.respStatus == 1) {
                        $("#" + this.pageId + "pinCode").val(this.lrtDtoRespForEwayApi.pinCode);
                        this.validatePincode();



                    } else {

                        console.log('getDetailsFromEwayBillRefreshEwb : no ewaybill details found!');
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the details from the EWay Bill Number", "warning");
            }, () => console.log('done');
    }

    clearEwayBillDetails() {
        $("#" + this.pageId + "pinCode").val('');
        $("#" + this.pageId + "destination").val('');
        this.destinationOptions = [];
        this.destinationTA = [];
    }
    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        inp._elementRef.nativeElement.focus();
    }



    validatePincode() {
        this.enteredPincode = $("#" + this.pageId + "pinCode").val();
        console.log('pincode length : ' + this.enteredPincode.length);
        if (this.enteredPincode == null || this.enteredPincode == "" || this.enteredPincode == undefined) {
            swal({
                title: "Not Allowed",
                text: "Please enter the destination pincode",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                window.setTimeout(function () {
                    $("#lrefcdestination").val('');
                    this.destinationOptions = [];
                    this.destinationTA = [];


                }, 100);
            })
        } else if (this.enteredPincode.length > 6 || this.enteredPincode.length < 6) {
            swal({
                title: "Invalid pincode",
                text: "Please enter the valid pincode!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                window.setTimeout(function () {
                    $("#lrefcdestination").val('');
                    this.destinationOptions = [];
                    this.destinationTA = [];

                }, 100);
            })
        } else {
            console.log('pincode validation');
            this.checkForPinCodeForSame();
        }
    }

    checkForPinCodeForSame() {
        this.destinationMainStation = null;
        this.enteredPincode = null;
        this.enteredPincode = $("#" + this.pageId + "pinCode").val();
        console.log("this.enteredPincode");
        console.log(this.enteredPincode);
        this.userDataDto = new UserDataDto();
        this.userDataDto.pinCodeStr = this.enteredPincode.trim();
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.isDeleted = false;
        //this.userDataDto.mainStation = this.destSplit;

        this.masterReadService.getPincodeDetailsForLrV2(this.userDataDto).subscribe(
            (response) => {
                if (response.status == "not found") {
                    swal({
                        title: "Warning",
                        text: "No destination found for the entered pincode, please verify and enter the correct pincode!",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        window.setTimeout(function () {
                            $("#destination").val('');
                            this.destinationOptions = [];
                            this.destinationTA = [];

                        }, 100);


                    })
                } else if (response.status == "success") {
                    $("#destination").val('');
                    this.destinationMainStation = response.mainAdminStation;
                    this.getEnteredPincodeStation(response.mainStation);
                } else if (response.status == "failed") {
                    swal({
                        title: "Server Error",
                        text: "Server Error While Getting Details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "Server Error While Getting Details",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }), () => console.log('done');
    }

    getEnteredPincodeStationDetailsList(station) {
        this.lrDtoSubstation = new LRDto();
        this.lrDtoSubstation.branch = this.userDataDtoReturnSession.office;
        this.lrDtoSubstation.mode = "LrFormPincodeStation";
        this.lrDtoSubstation.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSubstation.destination = station;
        this.lrDtoSubstation.pinCode = this.enteredPincode.trim();
    }

    getEnteredPincodeStation = (station) => {
        this.getEnteredPincodeStationDetailsList(station);
        this.masterReadService.getDestinationForLREntryForm(this.lrDtoSubstation).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Destination Records Found",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.controlDestination.reset();
                    this.destinationOptions = [];
                    this.destinationTA = [];
                } else {
                    this.controlDestination.reset();
                    console.log("dest response" + response);
                    this.destinationOptions = response;
                    this.destinationTA = [];

                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        console.log("this.destinationOptions[i]" + this.destinationOptions[i]);
                        console.log("this.destinationOptions[i].listOfDestination" + this.destinationOptions[i].listOfDestination);
                        console.log("this.destinationOptions[i].mainstration" + this.destinationOptions[i]);
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    setTimeout(() => {
                        const destinationField = document.getElementById(`${this.pageId}destination`) as HTMLInputElement;
                        destinationField.focus();
                    });
                }
                this.changeDetectorRef.detectChanges();
            },
            (error) => console.log(error),
            () => console.log('done')
        );
    }


    resetDestinationWithEmpty = () => {
        this.destinationOptions = [];
        this.destinationTA = [];
        for (let i = 0; i < this.destinationOptions.length; i++) {
            this.destinationTA.push(this.destinationOptions[i]);
        }
    }
    // printBarcodes(lrDtoBarcode) {
    //     document.getElementById('printSection')!.style.display = 'block';

    //     this.PrintBarcode = [];
    //     if (lrDtoBarcode.listOfBarcodes && lrDtoBarcode.listOfBarcodes.length) {
    //         lrDtoBarcode.listOfBarcodes.forEach((barcode, index) => {
    //             this.PrintBarcode.push({
    //                 barcode: barcode,
    //                 Name: lrDtoBarcode.lrNumber,
    //                 Destination: lrDtoBarcode.stationCode,
    //                 ArticleIndex: `${index + 1}/${lrDtoBarcode.totalArticles}`,
    //             });
    //         });

    //         setTimeout(() => {
    //             window.print();
    //         }, 1000);

    //         window.addEventListener("afterprint", () => {
    //             document.getElementById('printSection')!.style.display = 'none';
    //             this.validateClear();
    //             this.getHoldLrsDetails();
    //         });
    //     } else {
    //         swal("Error", "No barcodes available for printing", "info");
    //     }

    //     this.changeDetectorRef.detectChanges();
    // }




    onCancelPrint() {

        this.viewBarcodePrint = false;
        this.viewBarcodeEntryForm = true;


        this.PrintBarcode = [];


        localStorage.removeItem('printBarcodeData');
        this.validateClear();

        this.changeDetectorRef.detectChanges();
    }






    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestination = e.item;
        console.log(this.modelDestination);
        this.destination = this.modelDestination.destination;
        console.log(this.destination);
    }

    getBarcodeEntryData() {
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
        this.lrDtoBarcode = new LRDto();
        this.lrDtoBarcode.lrNumber = this.enteredLrNumber;
        this.lrDtoBarcode.totalArticles = this.articleCount;
        this.lrDtoBarcode.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoBarcode.entryBy = this.userDataDtoReturnSession.userId;
        this.lrDtoBarcode.destination = this.destinationMainStation;
        this.lrDtoBarcode.eWayBillNo = this.enteredEWayBillNo;

        console.log(this.lrDtoBarcode);
    }
    generateBarcode = () => {
        this.getBarcodeEntryData();
        this.showSpinnerForAction = true;

        this.lrService.addBarcodeDetails(this.lrDtoBarcode).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                this.lrDtoForLrRet = new LRDto();
                this.lrDtoForLrRet = response;

                if (this.lrDtoForLrRet.status === "Already Exists") {
                    swal({
                        title: "Warning",
                        text: "Barcode Already Generated for this LR",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else if (this.lrDtoForLrRet.status === "Success") {
                    // Send data to backend for printing
                    this.printBarcodes(this.lrDtoForLrRet);
                } else {
                    swal({
                        title: "Warning",
                        text: "Failed to generate barcode",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }

                this.changeDetectorRef.detectChanges();
            },
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred adding barcode details", "info");
            },
            () => console.log('done')
        );
    };

    // New method to send print request
    sendPrintRequest(lrDtoForLrRet: any) {
        const printRequest = {
            printerName: 'TSC TTP-244 Pro',
            barcodes: lrDtoForLrRet.listOfBarcodes,
            lrNumber: lrDtoForLrRet.lrNumber,
            stationCode: lrDtoForLrRet.stationCode,
            totalArticles: lrDtoForLrRet.totalArticles,
        };

        this.lrService.sendPrintRequest(printRequest).subscribe(
            (response) => {
                console.log('Print request sent successfully:', response);
                swal("Success", "Print job sent successfully", "success");
            },
            (error) => {
                console.error('Error sending print request:', error);
                swal("Error", "Failed to send print request", "error");
            }
        );
    }

    validateGenerateBarcode() {
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
        this.enteredPincode = $("#" + this.pageId + "pinCode").val();
        if (this.enteredLrNumber == null || this.enteredLrNumber == undefined || this.enteredLrNumber == ''
            //|| this.enteredEWayBillNo == null || this.enteredEWayBillNo == undefined || this.enteredEWayBillNo == ''
            || this.destination == null || this.destination == undefined || this.destination == ''
            || this.articleCount == null || this.articleCount == undefined || this.articleCount == 0
            || this.articleCount < 1) {

            swal({
                title: "Warning",
                text: "All Fields Are Mandatory",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        } else {
            this.generateBarcode();
        }
    }
    eWayBillNoFocus(e) {
        if (e.keyCode == 13) {
            this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
            if ((this.enteredEWayBillNo != null) && (this.enteredEWayBillNo != '')) {
                this.getDetailsFromEwayBillRefreshEwb();




            }
        }
    }
    setFieldsData(lrDetailsData: any) {
        this.selectedLrNumber = lrDetailsData.lrNumber;
        this.enteredLrNumber = lrDetailsData.lrNumber;
        $("#" + this.pageId + "eWayBillNo").focus();
        console.log("Selected LR Number:", this.selectedLrNumber);
        console.log("Entered LR Number:", this.enteredLrNumber);
    }

    clearManualLrsBarcodeGenPopupMtd() {
        $("#" + this.pageId + "manualGenArtcilesId").val('');
        $("#" + this.pageId + "manualGenLrNumberId").val('');
    }

    openManaulLRBarcodeGen(contentForLRArtWiseBarcodeGen) {

        this.modalService.open(contentForLRArtWiseBarcodeGen, { centered: true }).result.then(
            (result) => {
                this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );



    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    getManualBarcodeEntryPopupData() {
        this.enteredManualLrNumber = $("#" + this.pageId + "manualGenLrNumberId").val();
        this.enteredManualBarcodeArticles = $("#" + this.pageId + "manualGenArtcilesId").val();
        this.lrDtoBarcode = new LRDto();
        this.lrDtoBarcode.lrNumber = this.enteredManualLrNumber;
        this.lrDtoBarcode.totalArticles = this.enteredManualBarcodeArticles;
        this.lrDtoBarcode.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoBarcode.entryBy = this.userDataDtoReturnSession.userId;
        this.lrDtoBarcode.stocksAt = this.userDataDtoReturnSession.office;
        this.lrDtoBarcode.status = 'Pending';
        console.log(this.lrDtoBarcode);
    }
    generateBarcodeForLrsManual() {

        this.getManualBarcodeEntryPopupData();
        this.showSpinnerForAction = true;

        this.lrService.generateManualLRBarcode(this.lrDtoBarcode).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                this.lrDtoForLrRet = new LRDto();
                this.lrDtoForLrRet = response;

                if (this.lrDtoForLrRet.status === "Already Exists") {
                    swal({
                        title: "Warning",
                        text: "Barcode Already Generated for this LR! Please use Barcode Regenerate Page or contact admin",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else if (this.lrDtoForLrRet.status === "LR Not Found") {
                    swal({
                        title: "Warning",
                        text: "This LR Not belongs to " + this.userDataDtoReturnSession.office + " Stock Please verify it",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else if (this.lrDtoForLrRet.status === "Success") {
                    this.modalService.dismissAll();
                    this.printBarcodes(this.lrDtoForLrRet);
                } else {
                    swal({
                        title: "Warning",
                        text: "Failed to generate barcode",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }

                this.changeDetectorRef.detectChanges();
            },
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred adding barcode details", "info");
            },
            () => console.log('done')
        );

    }

    //Chg_V1
    validatePincodeOnFocus(e) {
        if (e.keyCode == 13) {
            $("#" + this.pageId + "destination").val('');
            this.destinationOptions = [];
            this.destinationTA = [];
            this.validatePincode();
        }
    }
    // printBarcodes(lrDtoBarcode: any) {
    //     if (!lrDtoBarcode.listOfBarcodes || !lrDtoBarcode.listOfBarcodes.length) {
    //         swal("Error", "No barcodes available for printing", "info");
    //         return;
    //     }

    //     // Prepare the barcode data
    //     this.PrintBarcode = lrDtoBarcode.listOfBarcodes.map((barcode: string, index: number) => ({
    //         barcode,
    //         Name: lrDtoBarcode.lrNumber,
    //         Destination: lrDtoBarcode.stationCode,
    //         ArticleIndex: `${index + 1}/${lrDtoBarcode.totalArticles}`,
    //     }));

    //     // Initialize QZ Tray
    //     qz.websocket.connect().then(() => {
    //         const config = qz.configs.create("TSC TTP-244 Pro");

    //         // Prepare data for printing
    //         const printData = this.PrintBarcode.map((PS) => ({
    //             type: 'html',
    //             format: 'plain',
    //             data: `
    //                 <div style="text-align: center; margin-bottom: 10px;">
    //                     <div style="font-size: 14px;">${PS.barcode}</div>
    //                     <svg style="width: 100%; height: auto;" data-value="${PS.barcode}" id="barcode${PS.barcode}"></svg>
    //                     <div>LR No. ${PS.Name}</div>
    //                     <div>${PS.Destination}</div>
    //                     <div>art. ${PS.ArticleIndex}</div>
    //                 </div>
    //             `,
    //         }));

    //         // Send print data
    //         return qz.print(config, printData);
    //     }).catch((err) => {
    //         console.error("Error connecting to printer:", err);
    //         swal("Error", "Failed to print barcodes", "info");
    //     }).finally(() => {
    //         qz.websocket.disconnect();
    //         this.validateClear();
    //         this.getHoldLrsDetails();
    //     });
    // }

    printBarcodes(lrDtoBarcode: any) {
        if (!lrDtoBarcode.listOfBarcodes || !lrDtoBarcode.listOfBarcodes.length) {
            swal("Error", "No barcodes available for printing", "info");
            return;
        }
    
        // Populate PrintBarcode array to use in the HTML
        this.PrintBarcode = lrDtoBarcode.listOfBarcodes.map((barcode: string, index: number) => ({
            barcode,
            Name: lrDtoBarcode.lrNumber,
            Destination: lrDtoBarcode.stationCode,
            ArticleIndex: `${index + 1}/${lrDtoBarcode.totalArticles}`,
        }));
    
        // Capture the HTML content of the `printSection`
        const printSection = document.getElementById('printSection');
        if (!printSection) {
            swal("Error", "Print section not found", "info");
            return;
        }
    
        const htmlContent = `
            <html>
            <head>
                <style>
                    /* General Styles */
                    .title_custom {
                        border-bottom: 1px solid orange;
                        margin: 0;
                        padding: 0;
                    }
                    .title_custom h6 {
                        font-weight: bold;
                        color: #333;
                    }
                    .card {
                        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                        margin-top: 10px;
                    }
                    .card-body h6 {
                        font-weight: bold;
                        font-size: 1rem;
                        color: #555;
                    }
                    .border-right {
                        border-right: 1px solid #ddd;
                    }
                    .table-borderless td,
                    .table-borderless th {
                        border: none;
                    }
                    .hyperLink_td {
                        color: blue;
                        cursor: pointer;
                    }
    
                    /* Responsive Styles */
                    @media (max-width: 1022px) {
                        h6 {
                            display: grid;
                        }
                    }
                    @media (min-width: 1023px) and (max-width: 1500px) {
                        b,
                        strong {
                            font-weight: bolder;
                            font-size: 13px;
                            display: inline-block;
                        }
                        span {
                            font-size: 13px;
                        }
                    }
    
                    /* Print Styles */
                    @media print {
                        body * {
                            visibility: hidden;
                        }
                        #printSection,
                        #printSection * {
                            visibility: visible;
                        }
                        #printSection {
                            position: absolute;
                            top: 0;
                            left: 0;
                            transform: rotate(-90deg) translate(-100%);
                            transform-origin: top left;
                            width: 100%;
                            height: 100%;
                        }
                        .barcode-text, .barcode-print, .barcode-details, .barcode-details-art {
                            margin: 0;
                            padding: 0;
                            text-align: center;
                            font-weight: bold;
                        }
                        .barcode-text {
                            font-size: 30px;
                            margin-top: 30px;
                        }
                        .barcode-print {
                            margin-top: 10px;
                            text-align: center;
                        }
                        .barcode-details, .barcode-details-art {
                            font-size: 50px;
                        }
                        .barcode-page {
                            page-break-after: always;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }
                        .barcode-page:first-of-type {
                            margin-top: 0 !important;
                            page-break-before: avoid;
                        }
                    }
                </style>
            </head>
            <body>
                ${printSection.innerHTML}
            </body>
            </html>
        `;
    
        // Use QZ Tray to print
        qz.websocket.connect()
            .then(() => {
                const config = qz.configs.create("Canon E470 series"); // Replace with your printer name
                const printData = [{
                    type: 'html',
                    format: 'plain',
                    data: htmlContent,
                }];
    
                return qz.print(config, printData);
            })
            .then(() => {
                swal("Success", "Barcodes printed successfully", "success");
            })
            .catch((err) => {
                console.error("Error during printing:", err);
                swal("Error", "Failed to print barcodes. Please check your printer connection.", "error");
            })
            .finally(() => {
                qz.websocket.disconnect()
                    .catch(disconnectErr => console.warn("Error during disconnection:", disconnectErr));
                
                // Clear and refresh after printing
                this.validateClear();
                this.getHoldLrsDetails();
            });
    }
    

}
