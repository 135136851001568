//CHG_VERINVLFL_01==Added a loading remarks columns as input field in maintable and normal column in popup table on 07062024
//Chg_V2 : Fixed added duplicate entry on popup table and remove ohter rows data (Asrar Jr / 15-10-2024)
//Chg_V3 : Loading article get sound click on add or minus as per discuss (Asrar Jr / 16-11-2024)
//Chg_V4 : Remove duplicate entry on loadedLRsDataList (Asrar Jr / 02-12-2024)
//Chg_V5 : Added remarks field  (Imran / 16-01-2025)
//Chg_V6 : Added Calculation for Per KG Invoice  (Imran / 23-01-2025)
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  ModalDismissReasons,
  NgbDateStruct,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { LrService } from "src/app/dataService/lr-service";
import { StockService } from "src/app/dataService/stock-service";
import { LRDto } from "src/app/dto/LR-dto";
import swal from "sweetalert";

@Component({
  selector: "app-invoice-loading-sheet-flow",
  templateUrl: "./invoice-loading-sheet-flow.component.html",
  styleUrls: ["./invoice-loading-sheet-flow.component.css"],
})
export class InvoiceLoadingSheetFlowComponent implements OnInit {
  loadingSheetDataList: any;
  model: NgbDateStruct;
  dtTriggerLoadingSheet: Subject<any> = new Subject();
  dtOptionsLoadingSheet: any;
  dtOptionsStockSummary: any;
  dtOptionsStockDetails: any;
  dtTriggerStockSummary: Subject<any> = new Subject();
  dtTriggerStockDetails: Subject<any> = new Subject();
  stockSummaryDataList: any;
  stockDetailsDataList: any;
  isLoggedIn = true;
  userDataDtoReturnSession: any;
  showSpinnerForAction = false;
  showSpinnerForAction2 = false;
  showSpinnerForAction3 = false;
  lrDtoSearch: LRDto = new LRDto();
  pageId = "ilsfc";
  selectedLoadingInvoiceData: any;
  selectedLoadingInvoiceNumber: any;
  loadingArticles: any;
  public modelLoadingArticle: any;
  summaryLrs: any;
  summaryActWt: any;
  summaryChgWt: any;
  summaryAmt: any;
  summaryArt: any;
  summaryLoadingArt: any;
  summaryBalArt: any;
  summaryTargetFrgt: any;
  summaryLoadedFrgt: any;
  summaryBalFrgt: any;
  summaryBalFrgtPerActWgt: any;
  summaryLoadedAW: any;
  summaryBalAW: any;
  summaryLoadedCW: any;
  summaryBalCW: any;
  ewayBillAlert = false;
  viewEwayBillStatus = false;
  lrDtoStockSummary: LRDto = new LRDto();
  lrDtoStocksDetails: LRDto = new LRDto();
  selectedStockDest: any;
  lrDtoUpdate: LRDto = new LRDto();
  lrDtosLoadingShortageUpdate: LRDto[];
  lrDtosLoadingUpdate: LRDto[];
  private unsavedChanges = false;
  lrDtoNewLR: LRDto = new LRDto();
  closeResult: string;
  lrDtoAutoLrPrint: LRDto = new LRDto();
  getvalueForLrPrint: any;
  setValueForLrPrint: LRDto = new LRDto();
  modalRefferenceLrPrintCopyPopUp: NgbModalRef;
  modalRefferenceLrPrintCopyClosePopUp: string;
  viewLrPrintCopyPopUp = false;
  loadedLRsDataList: any;
  dtTriggerloadedLRsDetail: Subject<any> = new Subject();
  dtOptionsloadedLRsDetail: any;
  lrDtosLoadingUpdateForSummary: any;
  barcodeInput: string = '';
  scanTimeout: any;
  summaryPerKgInv: any;
  constructor(
    private router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    private stockService: StockService,
    private lrService: LrService,
    private modalService: NgbModal,
    private hireslipService: HireslipService
  ) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      });
    }
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(
        sessionStorage.getItem("SRDWeb")
      );
      this.selectedLoadingInvoiceData = JSON.parse(
        localStorage.getItem("loadingSheetInvoiceData")
      );
      this.selectedLoadingInvoiceNumber = this.selectedLoadingInvoiceData.invoiceNumber;
      this.getInvoiceLoadinSheetDetails();
      this.getArticlesStocksSummaryDetailsList();
      if (this.userDataDtoReturnSession.ewayBillAlert) {
        this.ewayBillAlert = true;
        this.viewEwayBillStatus = true;
      }

    }
  }
  logInPage() {
    this.router.navigate(["/authentication/login"]);
  }

  ngOnInit(): void {
    (this.dtOptionsLoadingSheet = {
      dom: "Bfrtip",
      buttons: [
        {
          extend: "excel",
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: "Excel",
          footer: true,
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      processing: true,
      //scroll in datatable starts
      responsive: true,
      scrollX: true,
      scrollY: 400,
      scrollCollapse: true,
      paging: false,
      info: false,
    }),
      (this.dtOptionsStockSummary = {
        dom: "Bfrtip",
        buttons: [
          {
            extend: "excel",
            text: '<i class="fas fa-file-excel"> Excel</i>',
            titleAttr: "Excel",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            text: '<i class="fas fa-sync-alt"></i> Refresh',
            titleAttr: "Refresh",
            action: () => {
              this.getArticlesStocksSummaryDetailsList();


            },
          },
        ],
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search...",
        },
        processing: true,
        //scroll in datatable starts
        responsive: true,
        scrollX: true,
        scrollY: 230,
        scrollCollapse: true,
        pagingType: "full_numbers",
        // pageLength: 10,
        paging: false,
        info: false,
        footerCallback: function (row, data, start, end, display) {
          var api = this.api(),
            data;
          // converting to interger to find total
          var intVal = function (i) {
            return typeof i === "string"
              ? +i.replace(/[\$,]/g, "") * 1
              : typeof i === "number"
                ? i
                : 0;
          };
          var totLrs = api
            .column(2)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var articles = api
            .column(3)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var actWgt = api
            .column(4)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var chgWgt = api
            .column(5)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var totAmt = api
            .column(6)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          $(api.column(0).footer()).html("Total : " + data.length);
          $(api.column(2).footer()).html(totLrs);
          $(api.column(3).footer()).html(articles);
          $(api.column(4).footer()).html(actWgt);
          $(api.column(5).footer()).html(chgWgt);
          $(api.column(6).footer()).html(totAmt);
        },
      }),
      (this.dtOptionsStockDetails = {
        dom: "Bfrtip",
        buttons: [
          {
            extend: "excel",
            text: '<i class="fas fa-file-excel"> Excel</i>',
            titleAttr: "Excel",
            exportOptions: {
              columns: ":visible",
            },
          },
        ],
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search...",
        },
        processing: true,
        //scroll in datatable starts
        responsive: true,
        scrollX: true,
        scrollY: 230,
        scrollCollapse: true,
        pagingType: "full_numbers",
        // pageLength: 10,
        paging: false,
        info: false,
        footerCallback: function (row, data, start, end, display) {
          var api = this.api(),
            data;
          // converting to interger to find total
          var intVal = function (i) {
            return typeof i === "string"
              ? +i.replace(/[\$,]/g, "") * 1
              : typeof i === "number"
                ? i
                : 0;
          };

          var articles = api
            .column(5)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var actWgt = api
            .column(6)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var chgWgt = api
            .column(7)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var totAmt = api
            .column(8)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          $(api.column(0).footer()).html("Total : " + data.length);
          $(api.column(5).footer()).html(articles);
          $(api.column(6).footer()).html(actWgt);
          $(api.column(7).footer()).html(chgWgt);
          $(api.column(8).footer()).html(chgWgt);
        },
      });
    window.addEventListener('keydown', this.handleBarcodeScan);
  }

  ngOnDestroy(): void {
    this.dtTriggerLoadingSheet.unsubscribe();
    this.dtTriggerloadedLRsDetail.unsubscribe();
    window.removeEventListener('keydown', this.handleBarcodeScan);
  }
  ngAfterViewInit(): void {
    this.dtTriggerLoadingSheet.next();
    this.dtTriggerStockSummary.next();
    this.dtTriggerStockDetails.next();
    this.dtTriggerloadedLRsDetail.next();
  }

  getInvoiceLoadinSheetDetails() {
    this.lrDtoSearch = new LRDto();
    this.lrDtoSearch.invoiceNumber = this.selectedLoadingInvoiceNumber;
    this.lrDtoSearch.status = "Loading";
    this.showSpinnerForAction = true;
    this.stockService
      .getInvoiceLoadingSheetFlow(this.lrDtoSearch).subscribe((response) => {
        this.loadingSheetDataList = [];
        this.loadedLRsDataList = [];
        if (response.length > 0) {
          for (let i = 0; i < response.length; i++) {
            if (response[i].remArticles == 0) {
              this.loadedLRsDataList.push(response[i]);
            } else {
              this.loadingSheetDataList.push(response[i]);
            }
          }
        } else {
          swal("Alert", "No Records Found", "warning");
        }

        $("#" + this.pageId + "loadingSheetTableId").DataTable().destroy();
        this.dtTriggerLoadingSheet.next();

        $("#" + this.pageId + "loadedLRsDetailTableId").DataTable().destroy();
        this.dtTriggerloadedLRsDetail.next();
        this.updateSummary();

        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server error while getting details!", "warning");
      },
      () => console.log("done");
  }

  onMinusButtonClick(loadingSheetData) {
    if (loadingSheetData.loadingArticle > 0) {
      this.unsavedChanges = true;
      let curLoadingArticle = parseInt(loadingSheetData.loadingArticle);
      loadingSheetData.loadingArticle = curLoadingArticle - 1;
      loadingSheetData.remArticles = loadingSheetData.totalArticles - loadingSheetData.loadingArticle;

      //Chg_V3
      this.playLoadingSoundForMinus();

      this.updateLoadingSheetInvoiceLr(loadingSheetData);
    } else {
      console.log("lessthan zero");
    }
    // if (loadingSheetData.remArticles > 0) {
    //   const deleteRow = this.loadedLRsDataList.find((x) => x.lrNumber === loadingSheetData.lrNumber);
    //   this.loadedLRsDataList.splice(this.loadedLRsDataList.indexOf(deleteRow), 1);
    // }
  }

  onPlusButtonClick(loadingSheetData) {
    if (loadingSheetData.loadingArticle == undefined) {
      loadingSheetData.loadingArticle = 0;
    }
    if (loadingSheetData.loadingArticle < loadingSheetData.totalArticles) {
      this.unsavedChanges = true;
      let curLoadingArticle = parseInt(loadingSheetData.loadingArticle);
      loadingSheetData.loadingArticle = curLoadingArticle + 1;
      loadingSheetData.remArticles = loadingSheetData.totalArticles - loadingSheetData.loadingArticle;

      //Chg_V3
      this.playLoadingSoundForPlus();

      setTimeout(() => {
        this.updateLoadingSheetInvoiceLr(loadingSheetData);
      }, 10000);

      this.changeDetectorRef.detectChanges();
    } else {
      swal({
        title: "Not Allowed!",
        text: "Loading Articles is Reached to Actual Articles!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
    //$("#" + this.pageId + "loadingSheetTableId").DataTable().destroy();
    //this.dtTriggerLoadingSheet.next();
  }

  articleKeyPress(event: any, loadingSheetData) {
    //this.onPlusButtonClick(loadingSheetData);
    this.unsavedChanges = true;
    console.log(loadingSheetData.loadingArticle);
    if (loadingSheetData.loadingArticle < 0) {
      swal({
        title: "Not Allowed!",
        text: "Please input the correct Loading Articles!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
      loadingSheetData.loadingArticle = 0;
    } else if (loadingSheetData.loadingArticle <= loadingSheetData.totalArticles) {
      loadingSheetData.remArticles = loadingSheetData.totalArticles - loadingSheetData.loadingArticle;

      this.updateLoadingSheetInvoiceLr(loadingSheetData);
    } else {
      swal({
        title: "Not Allowed!",
        text: "Loading Articles is Reached to Actual Articles!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
      loadingSheetData.loadingArticle = 0;
    }
  }

  updateSummary() {
    this.summaryLrs = 0;
    this.summaryActWt = 0;
    this.summaryChgWt = 0;
    this.summaryAmt = 0;
    this.summaryArt = 0;
    this.summaryLoadingArt = 0;
    this.summaryBalArt = 0;
    this.summaryTargetFrgt = 0;
    this.summaryLoadedFrgt = 0;
    this.summaryBalFrgt = 0;
    this.summaryBalFrgtPerActWgt = 0;
    this.summaryLoadedAW = 0;
    this.summaryBalAW = 0;
    this.summaryLoadedCW = 0;
    this.summaryBalCW = 0;
    this.summaryPerKgInv = 0;


    this.lrDtosLoadingUpdateForSummary = [];

    for (let i = 0; i < this.loadingSheetDataList.length; i++) {
      this.lrDtosLoadingUpdateForSummary.push(this.loadingSheetDataList[i]);
    }

    for (let i = 0; i < this.loadedLRsDataList.length; i++) {
      this.lrDtosLoadingUpdateForSummary.push(this.loadedLRsDataList[i]);
    }

    console.log('LR Counts : ' + this.loadingSheetDataList.length + " - " + this.loadedLRsDataList.length + " - " + this.lrDtosLoadingUpdateForSummary.length);

    this.summaryLrs = this.lrDtosLoadingUpdateForSummary.length;
    for (let i = 0; i < this.lrDtosLoadingUpdateForSummary.length; i++) {
      //console.log('LR : ' + this.lrDtosLoadingUpdateForSummary[i].lrNumber + " - " + this.lrDtosLoadingUpdateForSummary[i].loadingArticle);
      this.summaryActWt =
        parseInt(this.lrDtosLoadingUpdateForSummary[i].actualWeight) + this.summaryActWt;
      this.summaryChgWt =
        parseInt(this.lrDtosLoadingUpdateForSummary[i].chargedWeight) +
        this.summaryChgWt;
      this.summaryAmt =
        parseInt(this.lrDtosLoadingUpdateForSummary[i].totalAmount) + this.summaryAmt;
      this.summaryArt =
        parseInt(this.lrDtosLoadingUpdateForSummary[i].totalArticles) + this.summaryArt;
      this.summaryLoadingArt =
        parseInt(this.lrDtosLoadingUpdateForSummary[i].loadingArticle) +
        this.summaryLoadingArt;
      this.summaryBalArt =
        parseInt(this.lrDtosLoadingUpdateForSummary[i].remArticles) + this.summaryBalArt;
      //currently target=tot frgt later we may need to work as per Musaddik bh
      this.summaryTargetFrgt =
        parseInt(this.lrDtosLoadingUpdateForSummary[i].totalAmount) +
        this.summaryTargetFrgt;

      let curFrgt = this.lrDtosLoadingUpdateForSummary[i].totalAmount;
      let curTotArticle = this.lrDtosLoadingUpdateForSummary[i].totalArticles;
      let curLoadingArticle = this.lrDtosLoadingUpdateForSummary[i].loadingArticle;
      let frgtPerArt = curFrgt / curTotArticle;
      let loadedFrgt = frgtPerArt * curLoadingArticle;
      this.summaryLoadedFrgt = loadedFrgt + this.summaryLoadedFrgt;
      if (this.summaryLoadedFrgt > 0) {
        this.summaryLoadedFrgt = +this.summaryLoadedFrgt.toFixed(2);
      }

      let curAw = this.lrDtosLoadingUpdateForSummary[i].actualWeight;
      let awPerArt = curAw / curTotArticle;
      let loadedAw = awPerArt * curLoadingArticle;
      this.summaryLoadedAW = loadedAw + this.summaryLoadedAW;
      if (this.summaryLoadedAW > 0) {
        this.summaryLoadedAW = +this.summaryLoadedAW.toFixed(2);
      }

      let curCw = this.lrDtosLoadingUpdateForSummary[i].chargedWeight;
      let cwPerArt = curCw / curTotArticle;
      let loadedCw = cwPerArt * curLoadingArticle;
      this.summaryLoadedCW = loadedCw + this.summaryLoadedCW;
      if (this.summaryLoadedCW > 0) {
        this.summaryLoadedCW = +this.summaryLoadedCW.toFixed(2);
      }
    }
    this.summaryBalFrgt = this.summaryTargetFrgt - this.summaryLoadedFrgt;
    this.summaryBalFrgt = +this.summaryBalFrgt.toFixed(2);

    this.summaryBalAW = this.summaryActWt - this.summaryLoadedAW;
    this.summaryBalAW = +this.summaryBalAW.toFixed(2);

    this.summaryBalCW = this.summaryChgWt - this.summaryLoadedCW;
    this.summaryBalCW = +this.summaryBalCW.toFixed(2);

    let frgtPerAW = this.summaryTargetFrgt / this.summaryActWt;
    this.summaryBalFrgtPerActWgt = +frgtPerAW.toFixed(2);
    // if (
    //   this.summaryTargetFrgt != null &&
    //   this.summaryArt != null &&
    //   this.summaryLoadingArt != null &&
    //   this.summaryArt !== 0
    // ) {
    //   this.summaryPerKgInv = parseFloat(
    //     ((this.summaryTargetFrgt / this.summaryArt) * this.summaryLoadingArt).toFixed(2)
    //   );
    // } else {

    //   this.summaryPerKgInv = 0;
    // }
    if (
      this.summaryLoadedFrgt != null &&
      this.summaryLoadedAW != null &&
      this.summaryArt !== 0
    ) {
      this.summaryPerKgInv = parseFloat
        ((this.summaryLoadedFrgt / this.summaryLoadedAW).toFixed(2)
        );
    } else {

      this.summaryPerKgInv = 0;
    }
    this.changeDetectorRef.detectChanges();
  }

  deleteInvoiceLoading(loadingSheetData) {
    swal({
      title: "Loading Invoice",
      text: "Sure you want to remove the selected LR from this Loading Sheet?",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        const deleteRow = this.loadingSheetDataList.find(
          (x) => x.lrNumber === loadingSheetData.lrNumber
        );
        this.loadingSheetDataList.splice(
          this.loadingSheetDataList.indexOf(deleteRow),
          1
        );
        $("#" + this.pageId + "loadingSheetTableId")
          .DataTable()
          .destroy();
        this.dtTriggerLoadingSheet.next();
        this.updateSummary();
        this.changeDetectorRef.detectChanges();
        this.deleteLrFromLoadingInvoice(loadingSheetData);
      }
    });
  }

  getArticlesStocksSummaryDetails() {
    this.lrDtoStockSummary = new LRDto();
    this.lrDtoStockSummary.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoStockSummary.branch = this.userDataDtoReturnSession.office;
    this.lrDtoStockSummary.branchType =
      this.userDataDtoReturnSession.stationType;
    this.lrDtoStockSummary.destType = this.userDataDtoReturnSession.destType;
    this.lrDtoStockSummary.destination =
      this.userDataDtoReturnSession.mainStation;
    this.lrDtoStockSummary.OfficeType =
      this.userDataDtoReturnSession.officeType;
    this.lrDtoStockSummary.reportMode = "Loadingsheet";
  }

  getArticlesStocksSummaryDetailsList() {
    this.getArticlesStocksSummaryDetails();
    this.showSpinnerForAction2 = true;
    this.stockService.getArticlesStocksSummary(this.lrDtoStockSummary).subscribe((response) => {
      if (response.length > 0) {
        this.stockSummaryDataList = [];
        $("#" + this.pageId + "stockSummaryTableId").DataTable().destroy();
        this.stockSummaryDataList = response;
        this.dtTriggerStockSummary.next();
      } else {
        swal("Alert", "No Record Found", "warning");
      }
      this.showSpinnerForAction2 = false;
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForAction2 = false;
        swal(
          "Error",
          "Server Error While Getting Trip Creation Stock Summary",
          "warning"
        );
      },
      () => console.log("done");
  }


  rowSelectedGetTripLRDeatils(stockSummaryTableData) {
    this.lrDtoStocksDetails.branch = this.userDataDtoReturnSession.office;
    this.stockDetailsDataList = [];
    $("#" + this.pageId + "stockDetailsTableId").DataTable().destroy();
    //this.dtTriggerStockDetails.next();
    this.lrDtoStocksDetails.destination = stockSummaryTableData.endDest;
    this.lrDtoStocksDetails.stationType = stockSummaryTableData.stationType;
    this.lrDtoStocksDetails.subStations = stockSummaryTableData.endDest;
    this.lrDtoStocksDetails.branchType =
      this.userDataDtoReturnSession.stationType;
    this.lrDtoStocksDetails.destType = this.userDataDtoReturnSession.destType;
    this.lrDtoStocksDetails.companyId = this.userDataDtoReturnSession.companyId;
    this.selectedStockDest = " - " + stockSummaryTableData.endDest;
    this.getArticlesStocksDetailsList();
  }

  getArticlesStocksDetailsList() {
    this.showSpinnerForAction3 = true;
    this.stockService
      .getArticlesStocks(this.lrDtoStocksDetails)
      .subscribe((response) => {
        this.stockDetailsDataList = [];
        $("#" + this.pageId + "stockDetailsTableId")
          .DataTable()
          .destroy();
        if (response.length > 0) {
          this.stockDetailsDataList = response;
          setTimeout(() => {
            this.dtTriggerStockDetails.next();
          }, 1000);

          if (this.userDataDtoReturnSession.ewayBillAlert) {
            this.ewayBillAlertMsg(this.stockDetailsDataList);
          } else {
            this.lrWithNoUnloadingDetailsAlertMsg(this.stockDetailsDataList);
          }

          this.lrWithNoUnloadingDetailsAlertMsg(this.stockDetailsDataList);
        }
        this.showSpinnerForAction3 = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction3 = false;
        swal(
          "Error",
          "Server Error While Getting Trip Creation Stock Details",
          "warning"
        );
      },
      () => console.log("done");
  }

  ewayBillAlertMsg(dataForEway) {
    var count = 0;
    var ewayBillStatus = "Multiple Lr's, No EWB";
    var lrs = "";
    for (let i = 0; i < dataForEway.length; i++) {
      if (
        dataForEway[i].ewayBillStatus != null &&
        dataForEway[i].ewayBillStatus == ewayBillStatus
      ) {
        if (count == 0) {
          lrs = dataForEway[i].lrNumber;
        } else {
          lrs += ", " + dataForEway[i].lrNumber;
        }
        count++;
      }
    }
    if (count >= 1) {
      swal({
        title:
          "LR Numbers without Eway Bill Number are listed below. Total:" +
          count +
          "",
        text: "" + lrs + "",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.lrWithNoUnloadingDetailsAlertMsg(this.stockDetailsDataList);
      });
    } else {
      this.lrWithNoUnloadingDetailsAlertMsg(this.stockDetailsDataList);
    }
  }

  lrWithNoUnloadingDetailsAlertMsg(data) {
    if (data != null && data != undefined) {
      if (data[0].reason != null) {
        swal({
          title:
            "LR Numbers with No Unloading details filled, please update the Unloading details from the Dashboard",
          text: data[0].reason,
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
      }
    }
  }

  validateLoadingSheetUpdate() {
    // alert('ok');
    if (this.summaryLoadingArt == 0) {
      swal(
        "Not Allowed",
        "No articles loaded to update the Loading Sheet!",
        "warning"
      );
    } else if (this.summaryBalArt > 0) {
      swal({
        title: "Incomplete Loading",
        text: "All the articles are not loaded into this Loading sheet, you want to mark these balance articles as Short?",
        icon: "warning",
        buttons: ["No", "Yes"],
      }).then((isConfirm) => {
        if (isConfirm) {
          this.updateLrShortageLoadingSheetInvoice();
        } else {
          this.confirmLoadingSheetUpdate();
        }
      });
    } else {
      this.confirmLoadingSheetUpdate();
    }
  }

  confirmLoadingSheetUpdate() {
    swal({
      title: "Confirm Update",
      text: "Sure your want to update this Invoice Loading Sheet?",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.updateLoadingSheetInvoice();
      }
    });
  }

  updateLrShortageLoadingSheetInvoice() {
    this.loadingSheetShortageLrsInvoiceData();
    this.showSpinnerForAction = true;
    this.lrService
      .updateLRsShortageFromLoadingInvoice(this.lrDtosLoadingShortageUpdate)
      .subscribe((response) => {
        if (response.status == "Success") {
          swal(
            "Success",
            "Invoice Loading sheet updated with Short details!",
            "info"
          );

          this.confirmLoadingSheetUpdate();
        } else {
          swal("Error", "Problem occurred while updating the details", "error");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server problem occurred while updating the details!",
          "error"
        );
      },
      () => console.log("done");
  }

  loadingSheetShortageLrsInvoiceData() {
    this.lrDtosLoadingShortageUpdate = [];
    for (let i = 0; i < this.loadingSheetDataList.length; i++) {
      if (this.loadingSheetDataList[i].remArticles > 0) {
        this.lrDtoUpdate = new LRDto();
        this.lrDtoUpdate.status = "Short";
        this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoUpdate.lrNumber = this.loadingSheetDataList[i].lrNumber;
        this.lrDtoUpdate.lrsShortage = this.loadingSheetDataList[i].remArticles;
        //CHG_VERINVLFL_01
        this.lrDtoUpdate.loadingRemarks = this.loadingSheetDataList[i].loadingRemarks
        this.lrDtoUpdate.remarks = "Loading Sheet";
        this.lrDtoUpdate.stocksAt = "Transit";
        this.lrDtoUpdate.invoiceNumber =
          this.selectedLoadingInvoiceData.invoiceNumber;
        this.lrDtoUpdate.vehicleNumber =
          this.selectedLoadingInvoiceData.vehicleNumber;
        this.lrDtoUpdate.fromStation =
          this.selectedLoadingInvoiceData.fromStation;
        this.lrDtoUpdate.toStation = this.selectedLoadingInvoiceData.toStation;
        this.lrDtoUpdate.destination =
          this.selectedLoadingInvoiceData.toStation;
        this.lrDtoUpdate.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtosLoadingShortageUpdate.push(this.lrDtoUpdate);
      }
    }
  }

  updateLoadingSheetInvoice() {
    this.loadingSheetInvoiceData();
    this.showSpinnerForAction = true;
    this.lrService
      .updateLrLoadingSheetInvoiceDetails(this.lrDtosLoadingUpdate).subscribe((response) => {
        if (response.status == "Success") {
          swal("Success", "Invoice Loading sheet updated!", "info");
        } else {
          swal("Error", "Problem occurred while updating the details", "error");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server problem occurred while updating the details!",
          "error"
        );
      },
      () => console.log("done");
  }

  loadingSheetInvoiceData() {
    this.lrDtosLoadingUpdate = [];
    for (let i = 0; i < this.loadingSheetDataList.length; i++) {
      this.lrDtoUpdate = new LRDto();
      this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;
      this.lrDtoUpdate.lrNumber = this.loadingSheetDataList[i].lrNumber;
      this.lrDtoUpdate.loadingArticle =
        this.loadingSheetDataList[i].loadingArticle;
      //CHG_VERINVLFL_01
      this.lrDtoUpdate.loadingRemarks =
        this.loadingSheetDataList[i].loadingRemarks;
      this.lrDtoUpdate.invoiceNumber =
        this.selectedLoadingInvoiceData.invoiceNumber;
      this.lrDtoUpdate.companyId = this.userDataDtoReturnSession.companyId;
      this.lrDtosLoadingUpdate.push(this.lrDtoUpdate);
    }

    for (let i = 0; i < this.loadedLRsDataList.length; i++) {
      this.lrDtoUpdate = new LRDto();
      this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;
      this.lrDtoUpdate.lrNumber = this.loadedLRsDataList[i].lrNumber;
      this.lrDtoUpdate.loadingArticle =
        this.loadedLRsDataList[i].loadingArticle;
      this.lrDtoUpdate.invoiceNumber =
        this.selectedLoadingInvoiceData.invoiceNumber;
      this.lrDtoUpdate.companyId = this.userDataDtoReturnSession.companyId;
      this.lrDtosLoadingUpdate.push(this.lrDtoUpdate);
    }

  }

  updateLoadingSheetInvoiceLr(loadingSheetData) {
    this.loadingSheetInvoiceLrData(loadingSheetData);
    this.lrService.updateLrLoadingSheetInvoiceSingleLrDetails(this.lrDtoUpdate)
      .subscribe((response) => {
        if (loadingSheetData.remArticles == 0) {
          console.log(loadingSheetData);
          console.log(this.loadedLRsDataList);
          //Chg_V2
          // this.loadedLRsDataList.push(loadingSheetData);
          if (!this.loadedLRsDataList.find(x => x.lrNumber === loadingSheetData.lrNumber)) {
            this.loadedLRsDataList.push(loadingSheetData);
          }
          //Chg_V4
          this.removeDuplicateLrs();

          //Chg_V2
          // const deleteRow = this.loadingSheetDataList.find((x) => x.lrNumber === loadingSheetData.lrNumber);
          // this.loadingSheetDataList.splice(this.loadingSheetDataList.indexOf(deleteRow), 1);
          const deleteRowIndex = this.loadingSheetDataList.findIndex((x) => x.lrNumber === loadingSheetData.lrNumber);
          if (deleteRowIndex !== -1) {
            this.loadingSheetDataList.splice(deleteRowIndex, 1);
          }
          // const dataTable = $("#" + this.pageId + "loadingSheetTableId").DataTable();
          // const rowsToDelete = dataTable.rows((idx, data) => data.lrNumber === loadingSheetData.lrNumber);
          // if (rowsToDelete.count() > 0) {
          //   rowsToDelete.remove().draw(false);
          // }
        }
        this.updateSummary();
        if (response.status != "Success") {
          swal("Error", "Problem occurred while updating the details", "error");
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server problem occurred while updating the details!",
          "error"
        );
      },
      () => console.log("done");
  }

  loadingSheetInvoiceLrData(loadingSheetData) {
    this.lrDtoUpdate = new LRDto();
    this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoUpdate.lrNumber = loadingSheetData.lrNumber;
    this.lrDtoUpdate.loadingArticle = loadingSheetData.loadingArticle;
    this.lrDtoUpdate.invoiceNumber = this.selectedLoadingInvoiceData.invoiceNumber;
    this.lrDtoUpdate.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoUpdate.remarks = loadingSheetData.remarks;
  }

  @HostListener("window:beforeunload", ["$event"])
  unloadNotification($event: any): void {
    if (this.unsavedChanges) {
      $event.returnValue = true;
      console.log("yes");
    } else {
      console.log("no");
    }
  }

  addStockLr(stockDetailsData) {
    swal({
      title: "Confirm Add",
      text: "Sure your want to add the selected LR into this Invoice Loading Sheet?",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.addLRIntoLoadingSheetInvoice(stockDetailsData);
      }
    });
  }

  addLRIntoLoadingSheetInvoice(stockDetailsData) {
    this.lrDtoNewLR = new LRDto();
    this.lrDtoUpdate = new LRDto();
    this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoUpdate.lrNumber = stockDetailsData.lrNumber;
    this.lrDtoUpdate.invoiceNumber =
      this.selectedLoadingInvoiceData.invoiceNumber;

    this.showSpinnerForAction = true;
    this.lrService
      .addLRIntoInvoiceLoadingSheetFlow(this.lrDtoUpdate)
      .subscribe((response) => {
        this.lrDtoNewLR = response;
        if (this.lrDtoNewLR.status == "Success") {
          swal(
            "Success",
            "LR added into Invoice Loading sheet updated!",
            "info"
          );

          this.loadingSheetDataList.push(this.lrDtoNewLR);
          $("#" + this.pageId + "loadingSheetTableId")
            .DataTable()
            .destroy();
          this.dtTriggerLoadingSheet.next();
          this.updateSummary();

          const deleteStockLrRow = this.stockDetailsDataList.find(
            (x) => x.lrNumber === stockDetailsData.lrNumber
          );
          this.stockDetailsDataList.splice(
            this.stockDetailsDataList.indexOf(deleteStockLrRow),
            1
          );
          $("#" + this.pageId + "stockDetailsTableId")
            .DataTable()
            .destroy();
          this.dtTriggerStockDetails.next();


        } else {
          swal("Error", "Problem occurred while adding the details", "error");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server problem occurred while adding the details!",
          "error"
        );
      },
      () => console.log("done");
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getDataForLrPrint(lrPrintCopyPopUpTemplate, lrNumber) {
    console.log(lrNumber);
    this.lrDtoAutoLrPrint = new LRDto();
    this.lrDtoAutoLrPrint.lrNumber = lrNumber;
    this.lrDtoAutoLrPrint.companyId = this.userDataDtoReturnSession.companyId;

    console.log(this.lrDtoAutoLrPrint);
    this.hireslipService
      .getAutoLrDetails(this.lrDtoAutoLrPrint)
      .subscribe((response) => {
        if (response.lrNumber != "" && response.lrNumber != null) {
          this.getvalueForLrPrint = "";
          this.getvalueForLrPrint = response;
          this.setValueForLrPrint = new LRDto();
          this.setValueForLrPrint = this.getvalueForLrPrint;
          this.setValueForLrPrint.lrEntryPrintMode = "";
          this.popUpPrintMethod(
            this.setValueForLrPrint,
            lrPrintCopyPopUpTemplate
          );
        } else {
          swal("Empty", "No Data Found", "info");
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal("Error", "", "info");
      },
      () => console.log("done");
  }

  popUpPrintMethod(setValueForLrPrint, lrPrintCopyPopUpTemplate) {
    localStorage.clear();
    localStorage.setItem(
      "printAfterSaveFromLrEnquiryData",
      JSON.stringify(setValueForLrPrint)
    );
    localStorage.setItem(
      "printAfterSaveFromLrEnquiryType",
      JSON.stringify("LrEnquiry")
    );

    this.modalRefferenceLrPrintCopyPopUp = this.modalService.open(
      lrPrintCopyPopUpTemplate,
      { centered: true, windowClass: "myCustomModalClass" }
    );
    this.modalRefferenceLrPrintCopyPopUp.result.then(
      (result) => {
        this.modalRefferenceLrPrintCopyClosePopUp = `Closed with: ${result}`;
      },
      (reason) => {
        this.modalRefferenceLrPrintCopyClosePopUp = `Dismissed ${this.getDismissReason(
          reason
        )}`;
      }
    );

    this.viewLrPrintCopyPopUp = true;
    window.addEventListener("afterPopUp", (event) => {
      this.viewLrPrintCopyPopUp = false;
    });
  }

  closeBtnPopup() {
    this.modalRefferenceLrPrintCopyPopUp.close();
  }
  popupClose() {
    this.modalService.dismissAll();
  }
  callLoadedLrs(contentLoadedLrs) {
    this.modalService.open(contentLoadedLrs, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  deleteLrFromLoadingSheetInvoice(loadingSheetData) {
    this.loadingSheetInvoiceLrData(loadingSheetData);
    this.lrService.deleteLrLoadingSheetInvoiceDetails(this.lrDtoUpdate).subscribe((response) => {
      if (response.status != "Success") {
        swal("Error", "Problem occurred while deleting the details", "error");
      }
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        swal(
          "Error",
          "Server problem occurred while deleting the details!",
          "error"
        );
      },
      () => console.log("done");
  }

  deleteLrFromLoadingInvoice(loadingSheetData) {
    this.loadingSheetInvoiceLrData(loadingSheetData);
    this.lrDtoUpdate.mode = 'invremove';
    this.lrService.deleteLrLoadingSheetInvoiceDetails(this.lrDtoUpdate).subscribe((response) => {
      if (response.status != "Success") {
        swal("Error", "Problem occurred while deleting the details", "error");
      }
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        swal(
          "Error",
          "Server problem occurred while deleting the details!",
          "error"
        );
      },
      () => console.log("done");
  }

  removeLoadedLrInvoiceLoading(loadedLRsData) {
    swal({
      title: "Confirm remove!",
      text: "Sure you want to remove the selected loaded LR?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        console.log('inside removeLoadedLrInvoiceLoading');
        loadedLRsData.loadingArticle = 0;
        loadedLRsData.remArticles = loadedLRsData.totalArticles;
        this.loadingSheetDataList.push(loadedLRsData);
        //$("#" + this.pageId + "loadingSheetTableId").DataTable().destroy();
        //this.dtTriggerLoadingSheet.next();
        this.deleteLrFromLoadingSheetInvoice(loadedLRsData);

        const deleteRow = this.loadedLRsDataList.find((x) => x.lrNumber === loadedLRsData.lrNumber);
        this.loadedLRsDataList.splice(this.loadedLRsDataList.indexOf(deleteRow), 1);

        this.updateSummary();
        //$("#" + this.pageId + "loadedLRsDetailTableId").DataTable().destroy();

        //console.log(loadedLRsData);
        //console.log(deleteRow);
        //$('#' + this.pageId + 'loadingSheetTableId').DataTable().row.add(loadedLRsData).draw();
        //$('#' + this.pageId + 'loadingSheetTableId').DataTable().draw();



        //this.dtTriggerloadedLRsDetail.next();

        this.changeDetectorRef.detectChanges();

      }
    });
  }

  callLoadedLrsSummary(contentLoadedLrSummary) {
    this.modalService.open(contentLoadedLrSummary, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  loadingInvoiceRemarksData(loadingSheetData) {
    this.lrDtoUpdate = new LRDto();
    this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoUpdate.lrNumber = loadingSheetData.lrNumber;
    this.lrDtoUpdate.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoUpdate.loadingRemarks = loadingSheetData.loadingRemarks;
  }


  updateLoadingInvRemarks(loadingSheetData) {

    this.loadingInvoiceRemarksData(loadingSheetData);
    this.lrService.updateLoadingInvRemarks(this.lrDtoUpdate)
      .subscribe((response) => {
        if (response.status != "Success") {
          swal("Error", "Problem occurred while updating the details", "error");
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server problem occurred while updating the details!",
          "error"
        );
      },
      () => console.log("done");

  }

  // Component.ts



  handleBarcodeScan = (event: KeyboardEvent) => {
    clearTimeout(this.scanTimeout); // Reset timeout each key press

    // Check for 'Enter' key, signifying the end of the barcode
    if (event.key === 'Enter') {
      this.processScannedBarcode(this.barcodeInput);
      this.barcodeInput = ''; // Reset input
    } else if (/^[a-zA-Z0-9]$/.test(event.key)) {
      // Append only alphanumeric characters
      this.barcodeInput += event.key;
    }

    // Set timeout to clear input if there's a pause in scanning
    this.scanTimeout = setTimeout(() => {
      this.barcodeInput = '';
    }, 500); // Adjust timeout as needed
  };



  processScannedBarcode(scannedLrNumber: string) {
    const loadingSheetData = this.loadingSheetDataList.find(data => data.lrNumber === scannedLrNumber);
    if (loadingSheetData && loadingSheetData.loadingArticle < loadingSheetData.totalArticles) {
      loadingSheetData.loadingArticle += 1;
      loadingSheetData.remArticles = loadingSheetData.totalArticles - loadingSheetData.loadingArticle;

      setTimeout(() => {
        this.updateLoadingSheetInvoiceLr(loadingSheetData);
      }, 10000);
      this.changeDetectorRef.detectChanges();
    } else {
      swal({
        title: "Not Allowed!",
        text: "Loading Articles has reached the total available articles!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  }

  //Chg_V3
  playLoadingSoundForPlus() {
    const audio = new Audio('assets/sounds/clap.mp3');
    audio.volume = 1.0;
    audio.play().catch(error => console.error("Error playing sound:", error));
  }

  playLoadingSoundForMinus() {
    const audio = new Audio('assets/sounds/garand-clip.mp3');
    audio.volume = 1.0;
    audio.play().catch(error => console.error("Error playing sound:", error));
  }

  //Chg_V4
  removeDuplicateLrs() {
    const uniqueLRNumbers = [];
    const uniqueList = [];

    for (const item of this.loadedLRsDataList) {
      if (uniqueLRNumbers.indexOf(item.lrNumber) === -1) {
        uniqueLRNumbers.push(item.lrNumber);
        uniqueList.push(item);
      }
    }
    this.loadedLRsDataList = uniqueList;
  }
  //Chg_V5
  updateLoadingInvoiceRemarks() {
    const remarksValue = $("#" + this.pageId + "invoiceLoadingRemarks").val();
    // this.lrDtoUpdate.remarks = remarksValue ? String(remarksValue) :null;
    if (remarksValue == null || remarksValue == undefined || remarksValue == '') {
      swal({
        title: "Warning",
        text: "Please enter remarks to update",
        icon: "warning",
        buttons: ["No", "Yes"],
      }).then((isConfirm) => {

      });
    } else {
      this.confirmLoadingInvoiceRemarksUpdate();
    }
  }
  confirmLoadingInvoiceRemarksUpdate() {
    swal({
      title: "Confirm Update",
      text: "Sure your want to update remarks for this invoice?",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.updateLoadingInvoiceRemarksMtd();
      }
    });
  }
  updateLoadingInvoiceRemarksMtd() {
    this.lrDtoUpdate = new LRDto();
    const remarksValue = $("#" + this.pageId + "invoiceLoadingRemarks").val();
    this.lrDtoUpdate.remarks = remarksValue ? String(remarksValue) : null;
    this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoUpdate.invoiceNumber = this.selectedLoadingInvoiceNumber;
    this.showSpinnerForAction = true;
    this.lrService
      .updateLoadingInvoiceRemarks(this.lrDtoUpdate).subscribe((response) => {
        if (response.status == "Success") {
          swal("Success", "Loading Invoice Remarks updated!", "info");
        } else {
          swal("Error", "Problem occurred while updating the details", "error");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server problem occurred while updating the details!",
          "error"
        );
      },
      () => console.log("done");
  }

}
