import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { LRDto } from 'src/app/dto/LR-dto';
import *  as moment from 'moment';
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { MemoReport } from 'src/app/dataService/memo-report';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DatePipe } from "@angular/common";


import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'app-gc-not-received-report',
    templateUrl: './gc-not-received-report.component.html',
    styleUrls: ['./gc-not-received-report.component.css']
})
export class GcNotReceivedReportComponent implements OnInit {

    gettingDataFrmServiceFrGcNotReceivedReportTable: any;
    gcNotReceivedReportDataList: any;
    onDestroyUnsubscribtionGcNotReceivedReport: Subscription;

    control = new FormControl();

    model: NgbDateStruct;
    model2;

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerGcNotReceivedReport: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsGcNotReceivedReport: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;

    lrDtoDestination: LRDto = new LRDto();
    validateDestination: any = null;
    validateTillDate: any = null;
    address: any;
    showSpinnerForAction: boolean = false;
    showSpinnerForActionForPopup = false;
    destinationStationOptions: LRDto[];
    lrDtoForDestinationStationAllOption: LRDto = new LRDto();
    public modelDestinationStation: any;
    destinationStationTA: Array<LRDto> = [];
    focusDestinationStationTA$ = new Subject<string>();
    searchDestinationStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationStationTA
                : this.destinationStationTA.filter(v => v.destination).slice(0, 200))));
        // : this.destinationStationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

    }
    formatterDestinationStation = (x: { destination: string }) => x.destination;

    memoData: CashMemoDto = new CashMemoDto();
    tillDatesModal: any = null;
    memoDataForPopup: CashMemoDto = new CashMemoDto();
    dataForPopup: any;
    memoDataFromPartyWiseRptPopup: CashMemoDto = new CashMemoDto();

    //for custom print
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    viewCustomPrintV1 = false;
    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    //Custom Print Footer
    summary1: number;
    summary2: number;
    //
    pageId = "gcnrc";
    gcNotRecRemarksTA: Array<any> = [];
    focusGcNotRecRemarksTA$ = new Subject<string>();
    gcNotRecRemarksSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusGcNotRecRemarksTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.gcNotRecRemarksTA
                : this.gcNotRecRemarksTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    lrDtoRemarks: LRDto = new LRDto();
    gcNotRecRemarksOptions: any;
    lrDtoForUpdateRemarks: LRDto = new LRDto;
    selectedRemarks: any;
    cashmemoNo: any;
        lrDtoForUpdateRet: LRDto = new LRDto;
    constructor(private reportService: ReportService, private masterReadService: MasterReadService
        , private router: Router, private datePipe: DatePipe, private memoReport: MemoReport, private modalService: NgbModal,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.getDestinationDataList();
            this.dataForPopup = JSON.parse(localStorage.getItem('gcNotRecReportPopUp'));
            localStorage.clear();
            if (this.dataForPopup != null) {
                this.memoDataFromPartyWiseRptPopup = new CashMemoDto();
                this.memoDataFromPartyWiseRptPopup = this.dataForPopup;
                if (this.memoDataFromPartyWiseRptPopup != null) {
                    this.gcNotReceivedDetailsListPopup();
                }
            }
            this.dataForPopup = '';
            this.getGcNotRecRemarksDetails();

        }

    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    ngOnInit(): void {
        var companyAddressDetls = this.address;
        this.dtOptionsGcNotReceivedReport = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return "G.C. Not Recieved From Billing - " +
                            "Godown : " + $("#gcnrcdestinationStationId").val() + " -  " +
                            "Till Date : " + moment($("#gcnrctillDates").val()).format('DD-MM-YYYY') + "";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var totalArticle = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var memoAmt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var hamalidd = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                //$(api.column(1).footer()).html(data.length);
                $(api.column(2).footer()).html(totalArticle);
                $(api.column(6).footer()).html(memoAmt);
                $(api.column(7).footer()).html(hamalidd);
            },
        }
    }


    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerGcNotReceivedReport.unsubscribe();
        //this.onDestroyUnsubscribtionGcNotReceivedReport.unsubscribe();
    }


    ngAfterViewInit(): void {
        this.dtTriggerGcNotReceivedReport.next();
    }


    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker


    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends

    clickListnerForDestinationStation(event) {
        this.modelDestinationStation = event.item;
        $("#" + this.pageId + "destinationStationId").val(this.modelDestinationStation.destination);
        this.validateDestination = $("#" + this.pageId + "destinationStationId").val();
        //console.log(this.validateDestination);
    }

    getDestinationData() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mainstation = this.userDataDtoReturnSession.mainStation;
        this.lrDtoDestination.mode = 'All';

    }
    getDestinationDataList() {
        this.showSpinnerForAction = true;
        this.getDestinationData();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Destination Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.destinationStationOptions = [];
                    this.destinationStationTA = [];
                    this.showSpinnerForAction = false;
                } else {
                    //console.log(response);
                    this.destinationStationOptions = response;
                    this.destinationStationTA = [];
                    $("#" + this.pageId + "destinationStationId").val('');
                    this.lrDtoForDestinationStationAllOption.destination = "All";
                    this.destinationStationTA.push(this.lrDtoForDestinationStationAllOption);
                    for (let i = 0; i < this.destinationStationOptions.length; i++) {
                        this.destinationStationTA.push(this.destinationStationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting destination Details", "warning");
            },
            () => console.log('done');
    };

    clearBtn() {
        this.modelDestinationStation = null;
        this.tillDatesModal = null;
        $("#" + this.pageId + "tillDates").val('');
        this.validateTillDate = null;
        this.validateDestination = null;
        $("#" + this.pageId + "gcNotReceivedReportTableId").DataTable().destroy();
        this.gcNotReceivedReportDataList = [];
        this.dtTriggerGcNotReceivedReport.next();
        this.memoDataForPopup = new CashMemoDto();
        this.memoDataFromPartyWiseRptPopup = new CashMemoDto();
        this.dataForPopup = '';
        this.showSpinnerForActionForPopup = true;
    }

    validateGCNotRecSearchBtn() {
        this.validateDestination = $("#" + this.pageId + "destinationStationId").val();
        this.validateTillDate = $("#" + this.pageId + "tillDates").val();
        if ((this.validateDestination == null) || (this.validateDestination == undefined) ||
            (this.validateDestination == "") || (this.validateTillDate == null) ||
            (this.validateTillDate == undefined) || (this.validateTillDate == "")) {
            swal("Mandatory Fields", "Please select the mandatory field", "warning");
            return false;
        } else {
            this.gcNotReceivedDetailsList();
        }
    }

    gcNotReceivedData() {
        this.memoData = new CashMemoDto();
        this.validateDestination = $("#" + this.pageId + "destinationStationId").val();
        this.validateTillDate = $("#" + this.pageId + "tillDates").val();
        if (this.validateDestination != null && this.validateDestination == "All") {
            this.memoData.mode = "All";
        } else {
            this.memoData.mode = "Specific";
        }
        this.memoData.mainStation = this.userDataDtoReturnSession.mainStation;
        this.memoData.godown = this.validateDestination;
        this.memoData.tillDate = this.validateTillDate;

    }
    gcNotReceivedDetailsList() {
        this.showSpinnerForAction = true;
        this.gcNotReceivedData();
        this.memoReport.getGCNotRecievedDetails(this.memoData).subscribe(
            (response) => {
                $("#" + this.pageId + "gcNotReceivedReportTableId").DataTable().destroy();
                this.gcNotReceivedReportDataList = [];
                if (response.length > 0) {
                    //console.log(response);
                    this.gcNotReceivedReportDataList = response;
                    this.showSpinnerForAction = false;
                } else {
                    this.gcNotReceivedReportDataList = [];
                    this.showSpinnerForAction = false;
                    swal("No Records", "No Records found for this search", "info");

                }
                this.dtTriggerGcNotReceivedReport.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the GC Not Received details", "info");
            }, () => console.log('done');
    }

    gcNotReceivedDataPopup() {
        this.memoDataForPopup = new CashMemoDto();
        this.memoDataForPopup.mode = this.memoDataFromPartyWiseRptPopup.mode;
        this.memoDataForPopup.mainStation = this.userDataDtoReturnSession.mainStation;
        this.memoDataForPopup.tillDate = this.memoDataFromPartyWiseRptPopup.tillDate;
        // console.log(this.showSpinnerForActionForPopup);
        console.log(this.memoDataForPopup);

    }
    gcNotReceivedDetailsListPopup() {
        this.showSpinnerForActionForPopup = true;
        this.gcNotReceivedDataPopup();
        // this.showSpinnerForActionForPopup = true;
        this.memoReport.getGCNotRecievedDetails(this.memoDataForPopup).subscribe(
            (response) => {
                //	  this.showSpinnerForActionForPopup = true;
                $("#" + this.pageId + "gcNotReceivedReportTableId").DataTable().destroy();
                this.gcNotReceivedReportDataList = [];
                // this.showSpinnerForActionForPopup = true;
                this.validateTillDate = this.memoDataForPopup.tillDate;
                $("#" + this.pageId + "tillDates").val(this.validateTillDate);
                if (response.length > 0) {
                    this.gcNotReceivedReportDataList = response;
                    this.showSpinnerForActionForPopup = false;
                } else {
                    this.gcNotReceivedReportDataList = [];
                    this.showSpinnerForActionForPopup = false;
                    swal("No Records", "No Records found for this search", "info");

                }
                console.log(this.showSpinnerForActionForPopup);
                this.dtTriggerGcNotReceivedReport.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                console.log(this.showSpinnerForActionForPopup);
                this.showSpinnerForActionForPopup = false;
                swal("Error", "Server Problem Occurred While getting the GC Not Received details", "info");
            }, () => console.log('done');
    }

    customPrintGCNotRec() {
        if (this.gcNotReceivedReportDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Memo Number", "LR Number", "Memo Date", "Art", "Consignee Name", "Memo Name", "Memo Amount", "Remarks"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [15, 10, 10, 5, 20, 20, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.summary1 = this.summary2 = 0;
            for (let i = 0; i < this.gcNotReceivedReportDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.gcNotReceivedReportDataList[i].memoNumber, this.gcNotReceivedReportDataList[i].lrNumber, this.gcNotReceivedReportDataList[i].memoDateStr, this.gcNotReceivedReportDataList[i].totalArticles, this.gcNotReceivedReportDataList[i].consignee, this.gcNotReceivedReportDataList[i].cashMemoName, this.gcNotReceivedReportDataList[i].amount, this.gcNotReceivedReportDataList[i].remarks];
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.summary1 = this.summary1 + this.gcNotReceivedReportDataList[i].totalArticles;
                this.summary2 = this.summary2 + this.gcNotReceivedReportDataList[i].amount;
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.gcNotReceivedReportDataList.length, "", "", this.summary1, "", "", this.summary2, ""];
            }
            //heading logics For Date
            this.fromDatePrint = this.datePipe.transform(this.memoData.tillDate, "dd-MM-yyyy");
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.fromDatePrint];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "G.C. Not Recieved From Billing");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }
    open(content, gcNotReceivedReportData) {
        this.cashmemoNo = null;
        this.cashmemoNo = gcNotReceivedReportData.memoNumber;
        this.modalService.open(content, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    getGCNotRecRemarksList() {

        this.lrDtoRemarks.companyId = this.userDataDtoReturnSession.companyId;
        //        console.log( 'mainstation has :' + this.hireDto.mainStation )
    }
    getGcNotRecRemarksDetails = () => {
        this.getGCNotRecRemarksList();
        this.masterReadService.getGcNotReceivedRemarksDetails(this.lrDtoRemarks).subscribe(
            (response) => {
                console.log(response);
                if (response.length == 0) {

                    swal({
                        title: "Warning",
                        text: "No Loader records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                    this.gcNotRecRemarksOptions = [];
                    this.gcNotRecRemarksTA = [];
                } else {

                    this.gcNotRecRemarksOptions = response;

                    this.gcNotRecRemarksTA = [];
                    for (let i = 0; i < this.gcNotRecRemarksOptions.length; i++) {
                        this.gcNotRecRemarksTA.push(this.gcNotRecRemarksOptions[i].remarks);
                    }


                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    updateGCNotRecRemarksBtn() {
        this.selectedRemarks = null;
        this.lrDtoForUpdateRemarks = new LRDto();
        this.selectedRemarks = $("#" + this.pageId + "gcNotRecRemarksId").val();


        if ((this.selectedRemarks == null) || (this.selectedRemarks == undefined) || (this.selectedRemarks == '')) {
            swal({
                title: "Mandatory Fields",
                text: "Please Select the Remarks",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                $("#lcrptupdateUnloaderId").focus();
            });
        } else {
            swal({
                title: "Updating Unloader",
                text: "Are you sure want to Update Remarks ",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.lrDtoForUpdateRemarks.memoNumber = this.cashmemoNo;
                    this.lrDtoForUpdateRemarks.remarks = this.selectedRemarks;
                    this.lrDtoForUpdateRemarks.userName = this.userDataDtoReturnSession.userName;
                    this.updatGCNotRecRemarksMethod();
                }
            });
        }
    }

    updatGCNotRecRemarksMethod() {
		this.showSpinnerForAction = true;
		
		console.log(this.lrDtoForUpdateRemarks);
		this.reportService.updateCashmemoGCNotRecRemarks(this.lrDtoForUpdateRemarks).subscribe(
			(response) => {
				console.log(response);
				this.lrDtoForUpdateRet = response;
				if (this.lrDtoForUpdateRet.status == 'success') {
					this.cashmemoNo = null;
					this.selectedRemarks = null;
					this.modalService.dismissAll();
					
					swal({
						title: "Success",
						text: "Unloader details upated successfully!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						this.validateGCNotRecSearchBtn();
					});

				} 
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while updating the unloader details", "error");
			}, () => console.log('done');
	}
}