import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LRDto } from "src/app/dto/LR-dto";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { DriverdetailsDto } from 'src/app/dto/Driverdetails-dto';
import { TruckDataDto } from "src/app/dto/TruckData-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { SupplierDetailsDto } from "src/app/dto/SupplierDetails-dto";
import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { FortNightStmtDto } from "src/app/dto/FortNightStmt-dto";
import { TripSheetDto } from "src/app/dto/TripSheet-dto";
import { serverUrl } from "src/environments/environment";
import { MessageDto } from '../dto/Message-dto';
import { ResponseDto } from '../dto/Response-dto';
import { CashMemoDto } from '../dto/CashMemo-dto';


@Injectable({
    providedIn: 'root'
})
export class MasterReadService {
    //for local
    //    private _baseUrl = "http://localhost:8080/srd/masterread/v1";
    //for QA
    //        private _baseUrl = "http://54.210.51.40:8080/srd/masterread/v1";
    //for Live
    //        private _baseUrl = "http://3.222.201.242:8080/srd/masterread/v1";

    private _baseUrl = serverUrl.webMasterRead;

    constructor(
        private http: HttpClient,
    ) {
    }

    getSubBookingStationDetailsService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getSubBookingStationDetails`, lrDto);
    }

    getSourceListDetails(agentDetailsDto: Object): Observable<AgentDetailsDto[]> {
        return this.http.post<AgentDetailsDto[]>(`${this._baseUrl}/getSourceList`, agentDetailsDto);
    }

    getEditCompany(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getEditCompanyMasterDetails`, userDataDto);
    }

    getDriver(driverDetailsDto: Object): Observable<DriverdetailsDto[]> {
        return this.http.post<DriverdetailsDto[]>(`${this._baseUrl}/getDriverDetails`, driverDetailsDto);
    }

    getNatureOfPack(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getNatureOfPack`, lrDto);
    }

    getTruck(truckDataDto: Object): Observable<TruckDataDto[]> {
        return this.http.post<TruckDataDto[]>(`${this._baseUrl}/getTruckDetails`, truckDataDto);
    }
    getConsignorMaster(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getConsignorDetails`, partyMasterDto);
    }

    getConsignorDetailsMasterDaoNew(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getConsignorDetailsMasterDaoNewApi`, partyMasterDto);
    }

    getSuplierMaster(supplierDetailsDto: Object): Observable<SupplierDetailsDto[]> {
        return this.http.post<SupplierDetailsDto[]>(`${this._baseUrl}/getSupplierDetails`, supplierDetailsDto);
    }

    getVehicleSetupMaster(truckDataDto: Object): Observable<TruckDataDto[]> {
        return this.http.post<TruckDataDto[]>(`${this._baseUrl}/getVehicleMaster`, truckDataDto);
    }

    getBankMasterDetails(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getBankDetails`, userDataDto);
    }

    getCollectionManMasterDetails(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getCollectionManDetails`, userDataDto);
    }

    getCommonChargesMasterDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getCommonChargesDetails`, rateMasterDto);
    }

    getUserDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getUserDetails`, lrDto);
    }

    getExpensesHeadsService(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getExpenseHeadDetails`, hireSlipDto);
    }

    getTimeService(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/getTimeDetails`, hireSlipDto);
    }

    getDebitNoteMaster(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getDebitNoteMasterDetails`, fortNightStmtDto);
    }

    getCommodityDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getCommodityMasterDetails`, rateMasterDto);
    }

    getConsignmentsClaimsSettlementEntry(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getConsignmentsClaimsSettlementEntryDetails`, lrDto);
    }

    getLorryHireDeductionService(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getLorryHireDeductionDetails`, hireSlipDto);
    }

    getBankMasterPdcDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBankPdcDetails`, lrDto);
    }

    getDestinationListDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getdestinationlistdetails`, lrDto);
    }

    getConsignorDetails(partyDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getconsignordetails`, partyDto);
    }


    getTripLrService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getTripLrDetails`, lrDto);
    }

    getDestinationForLREntryService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getDestinationForLREntry`, lrDto);
    }

    getFromStationService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getFromStationDetails`, lrDto);
    }

    getMainBookingStationsDetails(agentDetailsDto: Object): Observable<AgentDetailsDto[]> {
        return this.http.post<AgentDetailsDto[]>(`${this._baseUrl}/getmainbookingstationsdetails`, agentDetailsDto);
    }

    getStationCodeService(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getStationCode`, userDataDto);
    }

    getMsgDispInCMService(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getMsgDispInCMDetails`, lrDto);
    }

    getStateDetailsService(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getStateDetails`, partyMasterDto);
    }


    getLoaderHeadDetails(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getloaderheaddetails`, hireSlipDto);
    }

    getCommodityNamesDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getcommoditynamesdetails`, rateMasterDto);
    }

    getBranchAreaDetailsService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBranchAreaDetails`, lrDto);
    }


    getPartyBankDetailsService(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getPartyBankDetails`, userDataDto);
    }

    getConsigneeMaster(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getconsigneedetails`, partyMasterDto);
    }

    getDestinationForLREntryForm(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getdestinationforlrentryform`, lrDto);
    }
    getCityMainCommodityDetails(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getcitymaincommoditycetails`, partyMasterDto);
    }

    getConsigneeAddress(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getconsigneeaddress`, partyMasterDto);
    }

    getGoodsTypeCommdity(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getgoodstypecommdity`, rateMasterDto);
    }

    getAutomaticLrNo(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getautomaticlrno`, lrDto);
    }

    getBlockedLrsList(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getblockedlrslist`, lrDto);
    }

    validateFOVRateWhileLREntry(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/validatefovratewhilelrentry`, lrDto);
    }

    getAgentNameForChallanNo(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getagentnameforchallanno`, lrDto);
    }

    isCommodityAllowArtSize(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/iscommodityallowartsize`, lrDto);
    }

    addLRNumber(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/addlrnumber`, lrDto);

    }

    chkLREntryMissAgent(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/chklrentrymissagent`, lrDto);
    }

    validateLrBookingDate(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/validatelrbookingdate`, lrDto);
    }

    addLRDetails(lrDto: Object): Observable<LRDto[]> {
        //        console.log( lrDto );
        return this.http.post<LRDto[]>(`${this._baseUrl}/addlrdetails`, lrDto);
    }
    getAutoLrDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getautolrdetails`, lrDto);
    }
    getConsigneeRateDetailsForCashMemoService(rateMasterDto: Object): Observable<RateMasterDto[]> {
        //console.log( rateMasterDto );
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getConsigneeRateDetailsForCashMemo`, rateMasterDto);
    }
    getCommmodityMasterDetailsService(rateMasterDto: Object): Observable<RateMasterDto[]> {
        //console.log( rateMasterDto );
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getCommmodityMasterDetails`, rateMasterDto);
    }

    getCrossingChgService(lrDto: Object): Observable<LRDto[]> {
        //console.log( lrDto );
        return this.http.post<LRDto[]>(`${this._baseUrl}/getCrossingChg`, lrDto);
    }

    recalculateBkgMemoAfterAdminRateService(rateMasterDto: Object): Observable<RateMasterDto[]> {
        // console.log( rateMasterDto );
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/recalculateBkgMemoAfterAdminRateDtls`, rateMasterDto);
    }

    getLRDetailsAfterBkgMemoChgedService(lrDto: Object): Observable<LRDto[]> {
        //console.log( lrDto );
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLRDetailsAfterBkgMemoChged`, lrDto);
    }

    getCommodityFromAdminRateMater(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getCommodityFromAdminRateMater`, lrDto);
    }

    updateLrCopyTypeDetails(lrDto: Object): Observable<LRDto[]> {
        console.log(lrDto);
        return this.http.post<LRDto[]>(`${this._baseUrl}/updatelrcopytypedetails`, lrDto);
    }

    addConsignorInLrEntry(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/addconsignorinlrentry`, partyMasterDto);
    }
    addConsigneeInLrEntry(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/addconsigneeinlrentry`, partyMasterDto);
    }

    getDestMemoOtherChgDetails(rateMasterDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getDestMemoOtherChgDetails`, rateMasterDto);
    }

    verifyChallanTotalLrs(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/verifychallantotallrs`, lrDto);

    }

    getTruckTypes(truckDto: Object): Observable<TruckDataDto[]> {
        return this.http.post<TruckDataDto[]>(`${this._baseUrl}/getTruckTypes`, truckDto);
    }

    saveRCInsurancePanForTruckMasterFileUpload(formData: Object): Observable<FormData> {
        return this.http.post<FormData>(`${this._baseUrl}/saveRCInsurancePanForTruckMasterFileUpload`, formData);
    }

    getTruckDtlsInHireSlipGeneration(truckDataDto: Object): Observable<TruckDataDto[]> {
        return this.http.post<TruckDataDto[]>(`${this._baseUrl}/getTruckDtlsInHireSlipGeneration`, truckDataDto);
    }
    getBranchAreas(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBranchAreas`, lrDto);
    }

    uploadFile(formData: Object): Observable<FormData> {
        return this.http.post<FormData>(`${this._baseUrl}/uploadFileApi`, formData);
    }

    uploadFileTruckOwnerDec(formData: Object): Observable<FormData> {
        return this.http.post<FormData>(`${this._baseUrl}/uploadFileTruckOwnerDecApi`, formData);
    }

    getCheckPostList(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getCheckPostListApi`, lrDto);
    }

    addCheckPost(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/addCheckPostApi`, lrDto);
    }

    getLabourDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLabourDetailsApi`, lrDto);
    }

    getLoaderHeadsDetails(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getLoaderHeadsDetailsApi`, hireSlipDto);
    }

    getReasonsDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getReasonsDetailsApi`, lrDto);
    }

    //Article Grouping //added at 20-02-2021
    getStaffDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getStaffDetailsApi`, lrDto);
    }

    getTPCP(truckDataDto: Object): Observable<TruckDataDto[]> {
        return this.http.post<TruckDataDto[]>(`${this._baseUrl}/getTPCPApi`, truckDataDto);
    }

    getTruckCapacityDetails(truckDataDto: Object): Observable<TruckDataDto[]> {
        return this.http.post<TruckDataDto[]>(`${this._baseUrl}/getTruckCapacityDetailsApi`, truckDataDto);
    }

    getTripRoutingFeatureDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getTripRoutingFeatureDetailsApi`, lrDto);
    }

    getBranchSubstations(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBranchSubstationsApi`, lrDto);
    }

    getAdminStationsBranch(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getAdminStationsBranchApi`, hireSlipDto);
    }

    getTruckTypeCommonDetails(truckTypeDataDto: Object): Observable<TruckDataDto[]> {
        return this.http.post<TruckDataDto[]>(`${this._baseUrl}/getTruckTypeCommonDetailsApi`, truckTypeDataDto);
    }

    setTruckOwnerDetails(truckDataDto: Object): Observable<TruckDataDto[]> {
        return this.http.post<TruckDataDto[]>(`${this._baseUrl}/setTruckOwnerDetails`, truckDataDto);
    }

    // imran 03042021
    getUserNameList(lrdto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getUserNameListApi`, lrdto);
    }


    // lrdedit  29032021 @maaz
    getCommodityListForTP(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getCommodityListForTPApi`, rateMasterDto);
    }

    addTextMessage(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/addTextMessageApi`, hireSlipDto);
    }

    //send sms grp
    getSmsGroupHead(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getSmsGroupHeadApi`, lrDto);
    }

    getSmsGroupDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getSmsGroupDetailsApi`, lrDto);
    }

    addSmsGroupDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/addSmsGroupDetailsApi`, lrDto);
    }

    deleteSmsGroupDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/deleteSmsGroupDetailsApi`, lrDto);
    }

    deleteGroupName(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/deleteGroupNameApi`, lrDto);
    }

    editGroupName(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/editGroupNameApi`, lrDto);
    }

    addSmsGroupName(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/addSmsGroupNameApi`, lrDto);
    }
    //send sms grp - end

    //28052021
    getAgentName(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getAgentNameApi`, lrDto);
    }

    //04062021
    getDestCommdRateDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getDestCommdRateDetailsApi`, rateMasterDto);
    }
    addDestCommdRateDetails(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/addDestCommdRateDetailsApi`, rateMasterDto);
    }
    deleteDestCommdRateDetails(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/deleteDestCommdRateDetailsApi`, rateMasterDto);
    }

    /* consignee master start */
    getBookingMainStationsSource(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBookingMainStationsSourceApi`, lrDto);
    }
    /* consignee master end */

    getConsigneeMasterMobileNoUpdation(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getConsigneeMasterMobileNoUpdationApi`, partyMasterDto);
    }

    addNewCollectionManDetails(partyMasterDto: Object): Observable<PartyMasterDto> {
        return this.http.post<PartyMasterDto>(`${this._baseUrl}/addNewCollectionManDetailsApi`, partyMasterDto);
    }

    addNewDeliveryAreaDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/addNewDeliveryAreaDetailsApi`, lrDto);
    }

    /* consignor master start */
    getBookingMainStations(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBookingMainStationsApi`, lrDto);
    }
    /* consignor master end */

    getDesService(tripSheetDto: Object): Observable<TripSheetDto[]> {
        return this.http.post<TripSheetDto[]>(`${this._baseUrl}/getHeadDescApi`, tripSheetDto);
    }

    addDesService(tripSheetDto: Object): Observable<TripSheetDto[]> {
        return this.http.post<TripSheetDto[]>(`${this._baseUrl}/addHeadDescApi`, tripSheetDto);
    }

    getLocalTempoTripDtlsService(tripSheetDto: Object): Observable<TripSheetDto[]> {
        return this.http.post<TripSheetDto[]>(`${this._baseUrl}/getLocalTempoTripDetailsApi`, tripSheetDto);
    }

    getCommodityFromAdminRateMaster(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getCommodityFromAdminRateMasterApi`, lrDto);
    }

    getDestinationForLREntryFormForLessCheck(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getDestinationForLREntryFormForLessCheckApi`, lrDto);
    }

    getSmsDetails(partyDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getSmsDetailsApi`, partyDto);
    }

    getSmsInvoiceDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getSmsInvoiceDetailsApi`, lrDto);
    }

    getSmsTemplateDetails(messageDto: Object): Observable<MessageDto[]> {
        return this.http.post<MessageDto[]>(`${this._baseUrl}/getSmsTemplateDetailApi`, messageDto);
    }

    sendSmsDetails(messageDto: Object): Observable<MessageDto[]> {
        return this.http.post<MessageDto[]>(`${this._baseUrl}/sendSMSModuleApi`, messageDto);
    }

    calculateLRRateBasedOnConsignorRateService(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/calculateLRRateBasedOnConsignorRateApi`, rateMasterDto);
    }

    getPincodeStatusForLr(userDataDto: Object): Observable<UserDataDto> {
        return this.http.post<UserDataDto>(`${this._baseUrl}/getPincodeStatusForLrApi`, userDataDto);
    }

    getPincodeDetailsForLr(userDataDto: Object): Observable<UserDataDto> {
        return this.http.post<UserDataDto>(`${this._baseUrl}/getPincodeDetailsForLrApi`, userDataDto);
    }

    sendBkgLrWhatsAppMsg(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/sendBkgLrWhatsAppMsgApi`, lrDto);
    }

    getCityMainCommodityDetailsV2(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getcitymaincommoditydetailsv2`, partyMasterDto);
    }

    updateAocLrUploadStatus(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateAocLrUploadStatusApi`, lrDto);
    }

    uploadFileMemoPod(formData: Object): Observable<FormData> {
        return this.http.post<FormData>(`${this._baseUrl}/uploadFileMemoPodApi`, formData);
    }

    getVehicleTypesLocal(truckDataDto: Object): Observable<TruckDataDto[]> {
        return this.http.post<TruckDataDto[]>(`${this._baseUrl}/getVehicleTypesLocal`, truckDataDto);
    }

    getLocalDDDriverCharge(truckDataDto: Object): Observable<TruckDataDto[]> {
        return this.http.post<TruckDataDto[]>(`${this._baseUrl}/getLocalDDDriverCharge`, truckDataDto);
    }

    addUpdateVehicleAreaDriverChgDetails(truckDataDto: Object): Observable<TruckDataDto> {
        return this.http.post<TruckDataDto>(`${this._baseUrl}/addUpdateDriverChg`, truckDataDto);
    }

    getPincodeDetailsForLrV2(userDataDto: Object): Observable<UserDataDto> {
        return this.http.post<UserDataDto>(`${this._baseUrl}/getPincodeDetailsForLrV2Api`, userDataDto);
    }

    validateDestinationConsigneeLREntry(partyMasterDto: Object): Observable<PartyMasterDto> {
        return this.http.post<PartyMasterDto>(`${this._baseUrl}/validateDestinationConsigneeLREntryApi`, partyMasterDto);
    }

    enableLRCCCopyPrint(lrDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/enableLRCCCopyPrintApi`, lrDto);
    }

    updateLRHoldReason(lrDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/updateLRHoldReasonApi`, lrDto);
    }

    getSpecificConsigneeDetails(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getSpecificConsigneeDetailsApi`, partyMasterDto);
    }

    getTdsDetailsEntrySearch(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getTdsDetailsEntrySearchApi`, lrDto);
    }

    getTotalMemoLessAmountForTDSEntry(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getTotalMemoLessAmountForTDSEntryApi`, cashMemoDto);
    }

    getDestCommissionListSearch(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getDestCommissionListSearchApi`, fortNightStmtDto);
    }

    getDestIncomeListSearch(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getDestIncomeListSearchApi`, fortNightStmtDto);
    }

    getAreaDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getAreaDetailsApi`, lrDto);
    }

    getConsigneeMobilenoConfirmDetails(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getConsigneeMobilenoConfirmDetailsApi`, partyMasterDto);
    }

    updateConsigneeMobilenoConfirmDetailsService(partyMasterDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/updateConsigneeMobilenoConfirmDetailsApi`, partyMasterDto);
    }

    getConsignorMobilenoConfirmDetails(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getConsignorMobilenoConfirmDetailsApi`, partyMasterDto);
    }

    updateConsignorMobilenoConfirmDetailsService(partyMasterDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/updateConsignorMobilenoConfirmDetailsApi`, partyMasterDto);
    }

    validateConsignorMobilenoExistInConsigneeMaster(partyMasterDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/validateConsignorMobilenoExistInConsigneeMasterApi`, partyMasterDto);
    }

    validateConsigneeMobilenoExistInConsignorMaster(partyMasterDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/validateConsigneeMobilenoExistInConsignorMasterApi`, partyMasterDto);
    }

    getLRPodPinNo(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/getLRPodPinNoApi`, requestDto);
    }

    validateDefaultCFTFlowForDestinationLessParty(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/validateDefaultCFTFlowForDestinationLessPartyApi`, requestDto);
    }

    sendLrDeleteOtpPin(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/sendLrDeleteOtpServiceApi`, requestDto);
    }

    validateLRDeleteOtpPin(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/validateLRDeleteOtpApi`, requestDto);
    }

    getAgentSubstationDetailLists(agentDetailsDto: Object): Observable<AgentDetailsDto[]> {
        return this.http.post<AgentDetailsDto[]>(`${this._baseUrl}/getAgentSubstationDetailListsApi`, agentDetailsDto);
    }

    saveAgentSubstionDetails(agentDetailsDto: Object): Observable<AgentDetailsDto> {
        return this.http.post<AgentDetailsDto>(`${this._baseUrl}/saveAgentSubstionDetailsApi`, agentDetailsDto);
    }

    getAppModuleLists(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getAppModuleListsApi`, userDataDto);
    }

    saveUserAppModule(userDataDto: Object): Observable<UserDataDto> {
        return this.http.post<UserDataDto>(`${this._baseUrl}/saveUserAppModuleApi`, userDataDto);
    }

    getPerfMonitorConsignee(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getPerfMonitorConsigneeApi`, partyMasterDto);
    }

    getPerfMonitorConsignor(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getPerfMonitorConsignorApi`, partyMasterDto);
    }

    getPerKgRateDetailsForLR(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getPerKgRateDetailsForLRApi`, lrDto);
    }

    getCommoditySizeExceedDetailsForLR(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getCommoditySizeExceedDetailsForLRApi`, lrDto);
    }


    getBlockedCommodityDetails(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/getBlockedCommodityDetailsApi`, requestDto);
    }

    getSmsInvoiceDetailsIncludeLrNo(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getSmsInvoiceDetailsIncludeLrNoApi`, lrDto);
    }

    sendSmsDetailsIncludeLrNo(messageDto: Object): Observable<MessageDto[]> {
        return this.http.post<MessageDto[]>(`${this._baseUrl}/sendSmsDetailsIncludeLrNoApi`, messageDto);
    }

    sendWhatsAppDetailsIncludeLrNo(messageDto: Object): Observable<MessageDto[]> {
        return this.http.post<MessageDto[]>(`${this._baseUrl}/sendWhatsAppDetailsIncludeLrNoApi`, messageDto);
    }

    getCommodityFromAdminRateMasterGroupBySource(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getCommodityFromAdminRateMasterGroupBySourceApi`, lrDto);
    }

    sendWhatsAppDetailsIncludeLrNoVerloop(messageDto: Object): Observable<MessageDto[]> {
        return this.http.post<MessageDto[]>(`${this._baseUrl}/sendWhatsAppDetailsIncludeLrNoVerloopApi`, messageDto);
    }

    checkForLrBlockPartywiseConsigneeDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/checkForLrBlockPartywiseConsigneeDetailsApi`, lrDto);
    }

    getPartywiseDetailsForLrBlock(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getPartywiseDetailsForLrBlockApi`, lrDto);
    }

    saveLrBlockPartywiseDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/saveLrBlockPartywiseDetailsApi`, lrDto);
    }

    getLrBlockPartywiseDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrBlockPartywiseDetailsApi`, lrDto);
    }

    unblockLrPartywiseDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/unblockLrPartywiseDetailsApi`, lrDto);
    }

    getblockedlrslistForBarcode(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getblockedlrslistForBarcodeApi`, lrDto);
    }

    saveUnclearedLrdetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/saveUnclearedLrdetailsApi`, lrDto);
    }

    getReasonForUnclearedLrDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getReasonForUnclearedLrDetailsApi`, rateMasterDto);
    }

    getUnclearedLrDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getUnclearedLrDetailsApi`, lrDto);
    }

    clearAndMoveUnclearedLrDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/clearAndMoveUnclearedLrDetailsApi`, lrDto);
    }

    checkDuplicateEntryForSaveUnclearedLrDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/checkDuplicateEntryForSaveUnclearedLrDetailsApi`, lrDto);
    }

    getAreaDetailsFromRoutingAndAgentSubstation(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getAreaDetailsFromRoutingAndAgentSubstationApi`, lrDto);
    }

    getAreaDetailOfHamiliCharges(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getAreaDetailOfHamiliChargesApi`, lrDto);
    }

    calculateLRRateBasedOnConsigneeRateService(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/calculateLRRateBasedOnConsigneeRateServiceApi`, rateMasterDto);
    }

    uploadExcelForUpdateSubAreaConsigneeDtls(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/uploadExcelForUpdateSubAreaConsigneeDtlsApi`, requestDto);
    }

    getGcNotReceivedRemarksDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getGcNotReceivedRemarksDetailsApi`, lrDto);
    }
}
