export const environment = {
    production: true
};

let baseUrlMasterRead, baseUrlMaster, baseUrlConsignee, baseUrlConsignor, baseUrlDashboard, baseUrlMemo, baseUrlReport, baseUrlStock, baseUrlLogin, baseUrlMemoReport, baseUrlLrReport, baseUrlStockReport, baseUrlInvoice, baseUrlHireslip, baseUrlDestStmtRpt, baseUrlLr, baseUrlEwaybill: string;


baseUrlMasterRead = "/srd/masterread/v1";
baseUrlMaster = "/srd/master/v1";
baseUrlConsignee = "/srd/consignee/v1";
//baseUrlConsignor = "/srd/masterread/v1";
baseUrlDashboard = "/srd/dashboard/v1";
baseUrlMemo = "/srd/memo/v1";
baseUrlReport = "/srd/report/v1";
//baseUrlStock = "/srd/masterread/v1";
baseUrlLogin = "/srd/login/v1";

baseUrlMemoReport = "srd/memorpt/v1";
baseUrlLrReport = "srd/lrrpt/v1";
baseUrlStockReport = "/srd/stockrpt/v1";
baseUrlInvoice = "/srd/invoice/v1";
baseUrlHireslip = "/srd/hireslip/v1";
baseUrlDestStmtRpt = "/srd/deststmtrpt/v1";
baseUrlLr = "/srd/lr/v1";
baseUrlConsignor = "/srd/consignor/v1";
baseUrlEwaybill = "/srd/ewaybill/v1";

// baseUrlMasterRead = "http://localhost:8081/srd/masterread/v1";
// baseUrlMaster = "http://localhost:8081/srd/master/v1";
// baseUrlConsignee = "http://localhost:8081/srd/consignee/v1";
// //baseUrlConsignor = "http://localhost:8081/srd/masterread/v1";
// baseUrlDashboard = "http://localhost:8081/srd/dashboard/v1";
// baseUrlMemo = "http://localhost:8081/srd/memo/v1";
// baseUrlReport = "http://localhost:8081/srd/report/v1";
// //baseUrlStock = "http://localhost:8081/srd/masterread/v1";
// baseUrlLogin = "http://localhost:8081/srd/login/v1";

// baseUrlMemoReport = "http://localhost:8081/srd/memorpt/v1";
// baseUrlLrReport = "http://localhost:8081/srd/lrrpt/v1";
// baseUrlStockReport = "http://localhost:8081/srd/stockrpt/v1";
// baseUrlInvoice = "http://localhost:8081/srd/invoice/v1";
// baseUrlHireslip = "http://localhost:8081/srd/hireslip/v1";
// baseUrlDestStmtRpt = "http://localhost:8081/srd/deststmtrpt/v1";
// baseUrlLr = "http://localhost:8081/srd/lr/v1";
// baseUrlConsignor = "http://localhost:8081/srd/consignor/v1";
// baseUrlEwaybill = "http://localhost:8081/srd/ewaybill/v1";

export let serverUrl = {
    webMasterRead: baseUrlMasterRead,
    webMaster: baseUrlMaster,
    webConsignee: baseUrlConsignee,
    webDashboard: baseUrlDashboard,
    webMemo: baseUrlMemo,
    webReport: baseUrlReport,
    webStock: baseUrlMaster,
    webLogin: baseUrlLogin,
    webMemoReport: baseUrlMemoReport,
    webLrReport: baseUrlLrReport,
    webStockReport: baseUrlStockReport,
    webInvoice: baseUrlInvoice,
    webHireslip: baseUrlHireslip,
    webDestStmtRpt: baseUrlDestStmtRpt,
    webLr: baseUrlLr,
    webConsignor: baseUrlConsignor,
    webEwaybill: baseUrlEwaybill,
}
