<!-- ============================================================== -->
<!-- Sales chart -->
<!-- ============================================================== -->
<!-- <div class="row">
    <div class="col-12">
        <app-sales></app-sales>
    </div>
</div> -->
<!-- ============================================================== -->
<!-- Sales chart -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- Email campaign chart -->
<!-- ============================================================== -->
<!-- <div class="row">
    <div class="col-lg-8 col-xl-6">
        <app-email></app-email>
    </div>
    <div class="col-lg-4 col-xl-6">
        <app-visitor></app-visitor>
    </div>
</div> -->
<!-- ============================================================== -->
<!-- Email campaign chart -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- Ravenue - page-view-bounce rate -->
<!-- ============================================================== -->
<!-- <app-rpb></app-rpb> -->
<!-- ============================================================== -->
<!-- Ravenue - page-view-bounce rate -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- Table -->
<!-- ============================================================== -->
<!-- <div class="row"> -->
<!-- 	<div class="col-lg-12"> -->
<!-- 		<app-project></app-project> -->
<!-- 	</div> -->
<!-- </div> -->
<div class="row">
    <!-- 	SUMMARY STARTS -->
    <div class="col-md-12" *ngIf="viewSummary">
        <div class="col-md-12 m-b-20">
            <div class="card card-hover gredient-info-bg m-t-0 m-b-0">
                <div class="card-body">
                    <h5 class="card-title text-white">
                        <i class=" fas fa-file-alt"></i>&nbsp;Summary
                    </h5>
                    <app-summary></app-summary>
                </div>
            </div>
        </div>
    </div>
    <!-- 		<mat-spinner></mat-spinner> -->
    <!-- 		<div class="loading-overlay" > -->
    <!--   <mat-spinner></mat-spinner> -->
    <!-- </div> -->
    <!-- 	SUMMARY ENDS -->
    <!-- 	DELAYED TRIPS STARTS -->
    <div class="col-md-4" *ngIf='viewDelayedTrips'>
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Delayed Trips
							</h6>

						</div>
					</div>
				</div> -->
                <app-delayed-trips></app-delayed-trips>
            </div>
        </div>
    </div>
    <!-- 	DELAYED TRIPS ENDS -->
    <!-- 	FORTNIGHT STATEMENT SUMMARY STARTS -->
    <div class="col-md-4" *ngIf="viewFortnightStmtSummary">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Fortnight Statement
								Summary
							</h6>
						</div>
					</div>
				</div> -->
                <app-fortnight-statement-summary></app-fortnight-statement-summary>
            </div>
        </div>
    </div>
    <!-- 	FORTNIGHT STATEMENT SUMMARY ENDS -->
    <!-- 	SCHEDULED TRIPS FROM STARTS -->
    <div class="col-md-4" *ngIf="viewScheduleTripFrom">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Scheduled Trips From
							</h6>
						</div>
					</div>
				</div> -->
                <app-scheduled-trips-from></app-scheduled-trips-from>
            </div>
        </div>
    </div>
    <!-- 	SCHEDULED TRIPS FROM ENDS -->
    <!-- 	TRIPS ON TRANSIT FROM STARTS -->
    <div class="col-md-4" *ngIf="viewTripOnTransitFrom">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Trips On Transit From
							</h6>

						</div>
					</div>
				</div> -->
                <!-- THE BELOW CODE TRIPS ON TRANSIT IS COMMENTED DUE TO THE ALL THREE ARE SAME -->
                <app-trips-on-transit-from></app-trips-on-transit-from>
                <!-- <app-scheduled-trips-from></app-scheduled-trips-from> -->
            </div>
        </div>
    </div>
    <!-- 	TRIPS ON TRANSIT FROM ENDS -->
    <div class="col-md-4" *ngIf="viewTripOnTransit">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Trips On Transit
							</h6>
						</div>
					</div>
				</div> -->
                <!-- THE BELOW CODE TRIPS ON TRANSIT IS COMMENTED DUE TO THE ALL THREE ARE SAME -->
                <app-trips-on-transit></app-trips-on-transit>
                <!-- <app-scheduled-trips-from></app-scheduled-trips-from> -->
            </div>
        </div>
    </div>
    <!-- 	TRIPS ON TRANSIT TO STARTS -->
    <div class="col-md-4" *ngIf="viewTripOnTransitTo">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Trips On Transit To
							</h6>
						</div>
					</div>
				</div> -->
                <app-trips-on-transit-to></app-trips-on-transit-to>
            </div>
        </div>
    </div>
    <!-- 	TRIPS ON TRANSIT TO ENDS -->
    <!-- 	SCHEDULED TRIPS TO STARTS -->
    <div class="col-md-4" *ngIf="viewScheduleTripTo">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Scheduled Trips To
							</h6>
						</div>
					</div>
				</div> -->
                <app-scheduled-trips-to></app-scheduled-trips-to>
                <!--<app-scheduled-trips-from></app-scheduled-trips-from>-->
            </div>
        </div>
    </div>
    <!-- 	SCHEDULED TRIPS TO ENDS -->
    <!-- 	SOURCE STOCKS SUMMARY STARTS -->
    <!-- 	<div class="col-md-4"> -->
    <!-- 		<div class="col-md-12"> -->
    <!-- 			<div class="card bg-imbCustom text-black  card-hover"> -->
    <!-- 				<div class="card-body"> -->
    <!-- 					<div class="d-md-flex align-items-center"> -->
    <!-- 						<div> -->
    <!-- 							<h6 class="card-title"> -->
    <!-- 								<i class=" fas fa-file-alt"></i>&nbsp;Source Stocks Summary -->
    <!-- 							</h6> -->
    <!-- 						</div> -->
    <!-- 					</div> -->
    <!-- 				</div> -->
    <!-- 				<app-source-stocks-summary></app-source-stocks-summary> -->
    <!-- 			</div> -->
    <!-- 		</div> -->
    <!-- 	</div> -->
    <!-- 	SOURCE STOCKS SUMMARY ENDS -->
    <!-- 	STOCKS SUMMARY STARTS -->
    <div class="col-md-4" *ngIf="viewStocksSummary">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Stocks Summary
							</h6>
						</div>
					</div>
				</div> -->
                <app-stocks-summary></app-stocks-summary>
            </div>
        </div>
    </div>

    <!-- 	AGENT RANKING STARTS -->
    <div class="col-md-4" *ngIf="viewAgentRanking">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Agent Ranking
							</h6>
						</div>
					</div>
				</div> -->
                <app-agent-ranking></app-agent-ranking>
            </div>
        </div>
    </div>
    <!-- 	AGENT RANKING STARTS -->
    <!-- 	BOOKING AGENT STATEMENT STARTS -->
    <div class="col-md-4" *ngIf="viewBkgAgentStmtDtls">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Booking Agent Statement
							</h6>
						</div>
					</div>
				</div> -->
                <app-booking-agent-statement></app-booking-agent-statement>
            </div>
        </div>
    </div>
    <!-- 	BOOKING AGENT STATEMENT ENDS -->
    <!-- 	DOOR DELIVERY CONSIGNMENT NOT DELIVERED DETAILS STARTS -->
    <div class="col-md-4" *ngIf="viewDoorDelvLrsNotDelvd">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Door Delivery Consignment
								Not Delivered Details
							</h6>
						</div>
					</div>
				</div> -->
                <app-door-delivery-consignment-not-delivered-details></app-door-delivery-consignment-not-delivered-details>
            </div>
        </div>
    </div>
    <!-- 	DOOR DELIVERY CONSIGNMENT NOT DELIVERED DETAILS ENDS -->
    <!-- 	UNPAID BOOKING AGENT STATEMENT STARTS -->
    <div class="col-md-4" *ngIf="viewAgentUnpaidStmt">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Unpaid Booking Agent
								Statement
							</h6>
						</div>
					</div>
				</div> -->
                <app-unpaid-booking-agent-statement></app-unpaid-booking-agent-statement>
            </div>
        </div>
    </div>
    <!-- 	UNPAID BOOKING AGENT STATEMENT ENDS -->
    <!-- 	SRD LABOUR HAMALI STATEMENT STARTS -->
    <div class="col-md-4" *ngIf="viewLabourHamaliStmtSummary">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;SRD Labour Hamali
								Statement
							</h6>
						</div>
					</div>
				</div> -->
                <app-srd-labour-hamali-statement></app-srd-labour-hamali-statement>
            </div>
        </div>
    </div>
    <!-- 	SRD LABOUR HAMALI STATEMENT ENDS -->
    <!-- 	BOOKING STATIONS STOCK SUMMARY STARTS -->
    <!-- 	<div class="col-md-4"> -->
    <!-- 		<div class="col-md-12"> -->
    <!-- 			<div class="card bg-imbCustom text-black  card-hover"> -->
    <!-- 				<div class="card-body"> -->
    <!-- 					<div class="d-md-flex align-items-center"> -->
    <!-- 						<div> -->
    <!-- 							<h6 class="card-title"> -->
    <!-- 								<i class=" fas fa-file-alt"></i>&nbsp;Booking Stations Stocks -->
    <!-- 								Summary -->
    <!-- 							</h6> -->
    <!-- 						</div> -->
    <!-- 					</div> -->
    <!-- 				</div> -->
    <!-- 				<app-booking-stations-stocks-summary></app-booking-stations-stocks-summary> -->
    <!-- 			</div> -->
    <!-- 		</div> -->
    <!-- 	</div> -->
    <!-- 	BOOKING STATIONS STOCK SUMMARY ENDS -->
    <!-- 	LRS SHORT AND EXTRA STARTS -->
    <div class="col-md-4" *ngIf="viewLrsShortExtra">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;LRs Short & Extra
							</h6>
						</div>
					</div>
				</div> -->
                <app-lrs-short-and-extra></app-lrs-short-and-extra>
            </div>
        </div>
    </div>
    <!-- 	LRS SHORT AND EXTRA STARTS -->
    <!-- 	CASHMEMO PENDING FROM BIMMYMILLS STARTS -->
    <div class="col-md-4" *ngIf='viewCashmemoPendingFromBinny'>
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Cash Memo Pending From
								Binnymills
							</h6>
						</div>
					</div>
				</div> -->
                <app-cashmemo-pending-from-binnymills></app-cashmemo-pending-from-binnymills>
            </div>
        </div>
    </div>
    <!-- 	CASHMEMO PENDING FROM BIMMYMILLS ENDS -->
    <!-- 	LOCAL TRIPS STARTS -->
    <div class="col-md-4" *ngIf="viewLocalTrip">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Local Trips
							</h6>
						</div>
					</div>
				</div> -->
                <app-local-trips></app-local-trips>
            </div>
        </div>
    </div>
    <!-- 	LOCAL TRIPS ENDS -->
    <!-- 	PENDING HIRESLIP GENERATION STARTS -->
    <div class="col-md-4" *ngIf="viewPendingHireslipGeneration">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Pending Hireslip
								Generation
							</h6>
						</div>
					</div>
				</div> -->
                <app-pending-hireslip-generation></app-pending-hireslip-generation>
            </div>
        </div>
    </div>
    <!-- 	PENDING HIRESLIP GENERATION ENDS -->
    <!-- 	SCHEDULED LOCAL TRIPS STARTS -->
    <div class="col-md-4" *ngIf='viewScheduledLocalTrips'>
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Scheduled Local Trips
							</h6>
						</div>
					</div>
				</div> -->
                <app-scheduled-local-trips></app-scheduled-local-trips>
            </div>
        </div>
    </div>
    <!-- 	SCHEDULED LOCAL TRIPS ENDS -->
    <!-- 	CASHMEMO PENDING AND MISSING STARTS -->
    <div class="col-md-4" *ngIf='viewCashmemoPendingAndMissing'>
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Cash Memo Pending And
								Missing &nbsp;
								<!-- 														<app-lr-details-of-remaining-cash-memos></app-lr-details-of-remaining-cash-memos> -->
                <!--</h6>
						</div>
					</div>
				</div> -->
                <app-cashmemo-pending-and-missing></app-cashmemo-pending-and-missing>
            </div>
        </div>
    </div>

    <!-- 	CASHMEMO PENDING AND MISSING STARTS -->
    <!-- 	FINAL NOTICE PENDING EMAIL STARTS -->
    <div class="col-md-4" *ngIf='viewFinalNoticeEmail'>
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Final Notice Pending Email
							</h6>
						</div>
					</div>
				</div> -->
                <app-final-notice-pending-email></app-final-notice-pending-email>
            </div>
        </div>
    </div>
    <!-- 	FINAL NOTICE PENDING EMAIL ENDS -->
    <!-- 	PENDING CONSIGNEE STARTS -->
    <div class="col-md-4" *ngIf="viewPendingConsignee">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Pending Consignee
							</h6>
						</div>
					</div>
				</div> -->
                <app-pending-consignee></app-pending-consignee>
            </div>
        </div>
    </div>
    <!-- 	PENDING CONSIGNEE ENDS -->
    <!-- 	COD LR DETAILS STARTS -->
    <div class="col-md-4" *ngIf="viewCODLrDetails">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;COD LR Details
							</h6>
						</div>
					</div>
				</div> -->
                <app-cod-lr-details></app-cod-lr-details>
            </div>
        </div>
    </div>
    <!-- 	COD LR DETAILS ENDS -->
    <!-- 	EXPENSES OS PAYMENT FOLLOWUP STARTS -->
    <div class="col-md-4" *ngIf="viewExpenseOsPaymentFollowup">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Expenses OS Payment
								Followup
							</h6>
						</div>
					</div>
				</div> -->
                <app-expenses-os-payment-followup></app-expenses-os-payment-followup>
            </div>
        </div>
    </div>
    <!-- 	EXPENSES OS PAYMENT FOLLOWUP ENDS -->
    <!-- 	PENDING HAMALI MEMOS STARTS -->
    <div class="col-md-4" *ngIf="viewPendingHamaliMemos">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Pending Hamali Memos
							</h6>
						</div>
					</div>
				</div> -->
                <app-pending-hamali-memos></app-pending-hamali-memos>
            </div>
        </div>
    </div>
    <!-- 	PENDING HAMALI MEMOS ENDS -->
    <!-- 	PENDING STOCKS STARTS -->
    <div class="col-md-4" *ngIf="viewPendingStockSms">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Pending Stocks SMS
							</h6>
						</div>
					</div>
				</div> -->
                <app-pending-stocks-sms></app-pending-stocks-sms>
            </div>
        </div>
    </div>
    <!-- 	PENDING STOCKS ENDS -->
    <!-- 	TRUCKS REQUIRED FOR TRIP STARTS -->
    <div class="col-md-4" *ngIf="viewTrucksReqForTrip">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Truck Required For Trip <i
									style="float: right;"></i>
							</h6>
						</div>
					</div>
				</div> -->
                <app-trucks-required-for-trip></app-trucks-required-for-trip>
            </div>
        </div>
    </div>
    <!-- 	TRUCKS REQUIRED FOR TRIP ENDS -->
    <!-- 	TRIPS OF AGENT BOOKING STARTS -->
    <!-- 	<div class="col-md-4"> -->
    <!-- 		<div class="col-md-12"> -->
    <!-- 			<div class="card bg-imbCustom text-black  card-hover"> -->
    <!-- 				<div class="card-body"> -->
    <!-- 					<div class="d-md-flex align-items-center"> -->
    <!-- 						<div> -->
    <!-- 							<h6 class="card-title"> -->
    <!-- 								<i class=" fas fa-file-alt"></i>&nbsp;Trips Of Agent Booking -->
    <!-- 							</h6> -->
    <!-- 						</div> -->
    <!-- 					</div> -->
    <!-- 				</div> -->
    <!-- 				<app-trips-of-agent-booking></app-trips-of-agent-booking> -->
    <!-- 			</div> -->
    <!-- 		</div> -->
    <!-- 	</div> -->
    <!-- 	TRIPS OF AGENT BOOKING ENDS -->
    <!-- 	LEFT TO PARTY STATEMENT PENDING STARTS -->
    <div class="col-md-4" *ngIf='viewLeftToPartyStmtPending'>
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Left To Party Statements -
								Pending
							</h6>
						</div>
					</div>
				</div> -->
                <app-left-to-party-statements-pending></app-left-to-party-statements-pending>
            </div>
        </div>
    </div>
    <!-- 	LEFT TO PARTY STATEMENT PENDING ENDS -->
    <!-- 	TRANSIT LRS STARTS -->
    <div class="col-md-4" *ngIf="viewTransitLrs">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Transit Lrs
							</h6>
						</div>
					</div>
				</div> -->
                <app-transit-lrs></app-transit-lrs>
            </div>
        </div>
    </div>
    <!-- 	TRANSIT LRS ENDS -->
    <!-- 	BLOCK LR DETAILS STARTS -->
    <div class="col-md-4" *ngIf="viewBlockLrDetails">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Block Lr Details
							</h6>
						</div>
					</div>
				</div> -->
                <app-block-lr-details></app-block-lr-details>
            </div>
        </div>
    </div>
    <!-- 	PENDING COMMODITY STARTS -->
    <div class="col-md-4" *ngIf="viewPendingCommodity">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Pending Commodity
							</h6>
						</div>
					</div>
				</div> -->
                <app-pending-commodity></app-pending-commodity>
            </div>
        </div>
    </div>
    <!-- PENDING COMMODITY ENDS -->
    <!-- 	BLOCK LR DETAILS ENDS -->
    <!-- 	PENDING COMMODITY STARTS -->
    <!-- 	<div class="col-md-4"> -->
    <!-- 		<div class="col-md-12"> -->
    <!-- 			<div class="card bg-imbCustom text-black  card-hover"> -->
    <!-- 				<div class="card-body"> -->
    <!-- 					<div class="d-md-flex align-items-center"> -->
    <!-- 						<div> -->
    <!-- 							<h6 class="card-title"> -->
    <!-- 								<i class=" fas fa-file-alt"></i>&nbsp;Pending Commodity -->
    <!-- 							</h6> -->
    <!-- 						</div> -->
    <!-- 					</div> -->
    <!-- 				</div> -->
    <!-- 				<app-pending-commodity></app-pending-commodity> -->
    <!-- 			</div> -->
    <!-- 		</div> -->
    <!-- 	</div> -->
    <!-- PENDING COMMODITY ENDS -->
    <!-- 	Trips Of Agent Booking STARTS -->
    <div class="col-md-4" *ngIf="viewTripsOfAgentBkg">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Trips Of Agent Booking
							</h6>
						</div>
					</div>
				</div> -->
                <app-trips-of-agent-booking></app-trips-of-agent-booking>
            </div>
        </div>
    </div>
    <div class="col-md-4" *ngIf="viewTripsOfAgentBkgBarcode">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!-- <div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Trips Of Agent Booking
							</h6>
						</div>
					</div>
				</div> -->
                <app-trips-of-agent-booking-barcode></app-trips-of-agent-booking-barcode>
            </div>
        </div>
    </div>
    <!-- 	Trips Of Agent Booking ENDS -->

    <!-- 	Barcode Scanning STARTS -->
    <div class="col-md-4" *ngIf='viewBarcodeScanningFromKVH' style="margin-left: 30%;">
        <div class="col-md-12">
            <div class="card bg-imbCustom text-black  card-hover">
                <!--<div class="card-body">
					<div class="d-md-flex align-items-center">
						<div>
							<h6 class="card-title">
								<i class=" fas fa-file-alt"></i>&nbsp;Barcode Scanning
							</h6>
						</div>
					</div>
				</div>-->
                <app-barcode-scanning></app-barcode-scanning>
            </div>
        </div>
    </div>

    <!-- 	Barcode Scanning ENDS -->
    <!--Stocks Destination 	SUMMARY STARTS -->

    <!-- 	SUMMARY STARTS -->
    <div class="col-md-4" *ngIf="viewStocksDestinationSummary">
        <div class="col-md-12 ">
            <div class="card bg-imbCustom text-black  card-hover">

                <app-stocks-destination-summary></app-stocks-destination-summary>


            </div>
        </div>
    </div>
    <!-- 	STOCKS SUMMARY ENDS -->
</div>

<!-- ============================================================== -->
<!-- Table -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- Recent comment and chats -->
<!-- ============================================================== -->
<!-- <div class="row">
	column 
		<div class="col-lg-6">
			<app-comment></app-comment>
		</div>
		<!-- column 
		<div class="col-lg-6">
			<app-chat></app-chat>
		</div>
	</div> -->
<!-- ============================================================== -->
<!-- Recent comment and chats -->
<!-- ============================================================== -->